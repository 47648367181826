import React, { useState, useEffect,useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import "./style.scss";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { getLocalbody } from "../../helpers/globalFunctions";

const Intimation = () => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, settableData] = useState([])
  const [staffOptions, setStaffOptions] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [filterObject, setfilterObject] = useState({})
  const [slectdedValues, setslectdedValues] = useState({})
  const [modalData, setmodalData] = useState([])
  const formRef = useRef();

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetchAllIntimationlog()
    fetchallstaff()
  }, [])

  useEffect(() => {
    fetchAllIntimationlog()
  }, [currentLocalbody,filterObject])

//copied already existing code 
  const fetchallstaff = () => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + currentLocalbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };


  function handlesingleview(id){
    setModalOpen(true)

    axios.get(`${API_URL}intimation/view?id=${id}`,{
      headers:{
        "x-access-token":accessToken
      }
    }).then((res)=>{
      console.log(res,"resss");
      setmodalData(res.data.data[0])
      
    })
  }

  console.log(modalData,"modal data");
  


  function fetchAllIntimationlog() {
    axios.get(`${API_URL}intimation/log?fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&localbody=${currentLocalbody}&staff=${filterObject?.staff}&status=${filterObject?.status}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data
      result && result.map((item, index) => {
        item.id = index + 1;
        item.date = item?.date ? moment(item?.date).format("DD-MM-YYYY") : "--"
        item.time = item?.time ? moment(item.time, "hh:mm:s").format("hh:mm A") : "--"
        item.status = item?.collectMethod == "1" ? "Added" : item?.collectMethod == "2" ? "Missing" : item?.collectMethod == "3" ? "Partially" : item?.collectMethod == "4" ? "Adjust" : "--"
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => handlesingleview(item?._id)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        )
        return item

      })
      settableData(result)

    })
  }


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      {
        label: "Staff",
        field: "staffName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Drop ID",
        field: "dropUniqueId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Collected(kg)",
        field: "collected",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remaining(kg)",
        field: "remaining",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: tableData
  };


function handledate(e){
  let {name,value}=e.target
  setfilterObject({...filterObject,[name]:value})
}

function handleSelecetd(selected,name){
  setslectdedValues({...slectdedValues,[name]:selected})
  setfilterObject({...filterObject,[name]:selected.value})

}

function handleReset(){
 
  setfilterObject({})
  setslectdedValues({})

}
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Intimation" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody ref={formRef}>
                <AvForm>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">From</Label>
                        <AvField name="fromDate" type="date" onChange={handledate} value={filterObject?.fromDate} />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">To</Label>
                        <AvField name="todate" type="date" onChange={handledate} value={filterObject?.todate} />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <ReactSelect
                          name="Staff"
                          classNamePrefix="select2-selection"
                          options={staffOptions}
                          onChange={(selected)=>{
                            handleSelecetd(selected,"staff")
                          }}
                          value={slectdedValues?.staff||null}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <ReactSelect
                          name="Customer"
                          classNamePrefix="select2-selection"
                          options={[{label:"Added",value:1},{label:"Missing",value:2},{label:"Partially",value:"3"},{label:"adjust",value:4}]}
                          onChange={(selected)=>{
                            handleSelecetd(selected,"status")
                          }}
                          value={slectdedValues?.status||null}

                        />
                      </div>
                    </Col>
                    <Col md="1" style={{ paddingTop: "4px" }}>
                      <div className="mt-4">
                        <Button color="danger" type="button" onClick={handleReset}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
                <MDBDataTable
                  id="intimationId"
                  responsive
                  bordered
                  data={data}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          show={modalOpen}
          toggle={handleCloseModal}
          size="lg"
          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">Intimation </h5>
            <button
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div class="col-md-12">
                <ul className="lead-details">
                  <li>
                    Date: <label class="form-label">{modalData?.date?moment(modalData?.date).format("DD-MM-YYYY"):"--"}</label>
                  </li>
                  <li>
                    Time: <label class="form-label">{modalData?.time?moment(modalData.time,"hh:mm:s").format("hh:mm A"):"--"}</label>
                  </li>
                  <li>
                    Staff: <label class="form-label">{modalData?.staffName}</label>
                  </li>
                  <li>
                    Status: <label class="form-label">{modalData?.collectMethod == "1" ? "Added" : modalData?.collectMethod == "2" ? "Missing" : modalData?.collectMethod == "3" ? "Partially" : modalData?.collectMethod == "4" ? "Adjust" : "--"}</label>
                  </li>
                  <li>
                    Collected : <label class="form-label">{modalData?.collected} kg</label>
                  </li>
                  <li>
                    Remaining : <label class="form-label">{modalData?.remaining} kg</label>
                  </li>
                  <li>
                    Drop ID: <label class="form-label">{modalData?.dropUniqueId}</label>
                  </li>
                  <li>
                  Description : <label class="form-label">{modalData?.description}</label>
                  </li>
                 
                </ul>
              </div>
              <div class="col-md-12">
                <ul className="lead-details">
                  <li>
                    Image :
                    <label className="form-label">
                      <img
                        className="me-2"
                        style={{ width: "100px", height: "100px" }}
                        src={`${API_URL}uploads/drpwaste/${modalData?.image}`}
                        alt={``}
                      />
                    </label>
                  </li>
                  {/* <li>
                    Audio :
                    <label class="form-label">
                      <audio controls style={{ height: "35px" }}>
                        <source src={``} target="_blank" type="audio/mpeg" />
                       
                        Your browser does not support the audio element.
                      </audio>
                    </label>
                  </li> */}
                </ul>
              </div>
              <div class="col-md-12">
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Initial  Weight</th>
                        <th>Initial Bag</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{modalData?.itemName}</td>
                        <td>{modalData?.initialWeight}Kg</td>
                        <td> {modalData?.initialBag}</td>
                      </tr>
                      {/* <tr>
                        <td>Plastics</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Chappals</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Intimation;
