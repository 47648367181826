import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { getLocalbody } from "../../../../helpers/globalFunctions";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import Select from "react-select";

const Billings = () => {

    const [selected, setselected] = useState({})
    const data = {
        columns: [
            { label: "#", field: "id", sort: "asc", width: 150 },
            { label: "Date", field: "date", sort: "asc", width: 150 },
            { label: "Party", field: "party", sort: "asc", width: 270 },
            { label: "Invoice", field: "invoive", sort: "asc", width: 150 },
            { label: "Item", field: "item", sort: "asc", width: 150 },
            { label: "Decription ", field: "decsription", sort: "asc", width: 150 },
            { label: "Code", field: "code", sort: "asc", width: 150 },
            { label: "HSN/SAC", field: "hnc", sort: "asc", width: 150 },
            { label: "Qty", field: "qty", sort: "asc", width: 150 },
            { label: "Unit", field: "unit", sort: "asc", width: 150 },
            { label: "Price", field: "price", sort: "asc", width: 150 },
            { label: "Discount", field: "discount", sort: "asc", width: 150 },
            { label: "GST", field: "gst", sort: "asc", width: 150 },
            { label: "Amount", field: "amount", sort: "asc", width: 150 },
        ],
        rows: [
            { id: "1", date: "12-05-2024", party: "Al Noor Plastic", invoive: "72", decsription: "kannur", code: "NLK5", hnc: "3915", qty: "10650.00", unit: "kg", price: "2.5", discount: "2665(10%)", gst: "4313(10%)", amount: "4567", item: "plastics" },
            { id: "2", date: "12-05-2024", party: "Al Noor Plastic", invoive: "72", decsription: "kannur", code: "NLK5", hnc: "3915", qty: "10650.00", unit: "kg", price: "2.5", discount: "2665(10%)", gst: "4313(10%)", amount: "4567", item: "plastics" },
            { id: "3", date: "12-05-2024", party: "Al Noor Plastic", invoive: "72", decsription: "kannur", code: "NLK5", hnc: "3915", qty: "10650.00", unit: "kg", price: "2.5", discount: "2665(10%)", gst: "4313(10%)", amount: "4567", item: "plastics" }

        ]
    };



    function handleselected(name, select) {
        setselected({ ...selected, [name]: select.value })

    }
    console.log(selected, "selected");

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Billings" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <h6>From</h6>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Ware House</Label>
                                                    <Select options={[{ label: "Main", value: "1" }]} />
                                                </div>
                                            </Col>
                                            <Col md="2">
                                                <div className="mb-3">
                                                    <Label>Type</Label>
                                                    <Select options={[{ label: "Bale", value: "1" }, { label: "Bag", value: "2" }]} />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Items</Label>
                                                    <Select options={[{ label: "Test Items", value: "1" }]} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>To</Label>
                                                    <Select onChange={(selected) => {
                                                        handleselected("to", selected)
                                                    }} options={[{ label: "Ware House", value: "1" }, { label: "Outside", value: "2" }]} />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Type</Label>
                                                            <Select

                                                                // isDisabled={selected?.to == "2"}
                                                                onChange={(selected) => {
                                                                    handleselected("type", selected)
                                                                }} options={[{ label: "Delivery Notes", value: "1" }, { label: "Billing", value: "2" }]} />
                                                        </div>
                                                    </Col>
                                            {selected?.to == "1" ? (
                                                <>
                                                    <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Ware House</Label>
                                                            <Select
                                                                options={[{ label: "Main", value: "1" }, { label: "mini", value: "1" }]} />
                                                        </div>
                                                    </Col>
                                                   
                                                    {selected?.type == "1" ? (
                                                        <>
                                                            <Col md="2">
                                                                <div className="mb-3">
                                                                    <Label>Quantity</Label>
                                                                    <AvField name="qty" placeholder="qty" />
                                                                </div>
                                                            </Col>
                                                            <Col md="2">
                                                                <div className="mb-3">
                                                                    <Label>Unit</Label>
                                                                    <AvField name="qty" placeholder="Unit" />
                                                                </div>
                                                            </Col>
                                                            <Col md="2">
                                                                <div className="mb-3">
                                                                    <Label>Price</Label>
                                                                    <AvField name="qty" placeholder="Price" />
                                                                </div>
                                                            </Col>

                                                        </>
                                                    ) : null}

                                                </>
                                            ) : null}
                                            {/* outside  */}
                                            {selected?.to == "2" || (selected?.to == "1" && selected?.type == "2") ? (
                                                <>
                                                    {/* <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Type</Label>
                                                            <Select
                                                                defaultValue={[{ label: "Billing", value: "2" }]}
                                                                isDisabled={true}
                                                            />
                                                        </div>
                                                    </Col> */}
                                                    <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Party</Label>
                                                            <Select onChange={(selected) => {
                                                                handleselected("party", selected)
                                                            }} options={[{ label: "Al noor Plastic", value: "1" }, { label: "Billing", value: "2" }]} />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Item</Label>
                                                            <Select onChange={(selected) => {
                                                                handleselected("party", selected)
                                                            }} options={[{ label: "Plastic", value: "1" }, { label: "Thermocol", value: "2" }]} />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Invoice</Label>
                                                            <AvField name="qty" placeholder="Invoice" />
                                                        </div>
                                                    </Col>
                                                    <Col md="3">
                                                        <div className="mb-3">
                                                            <Label>Decription</Label>
                                                            <AvField name="qty" placeholder="Decription" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Code</Label>
                                                            <AvField name="qty" placeholder="code" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>HSN/HAC</Label>
                                                            <AvField name="qty" placeholder="HSN/HAC" />
                                                        </div>
                                                    </Col>

                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Quantity</Label>
                                                            <AvField name="qty" placeholder="qty" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Unit</Label>
                                                            <AvField name="qty" placeholder="Unit" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Price</Label>
                                                            <AvField name="qty" placeholder="Price" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Discount</Label>
                                                            <AvField name="qty" placeholder="Discount" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>GST</Label>
                                                            <AvField name="qty" placeholder="GST" />
                                                        </div>
                                                    </Col>
                                                    <Col md="2">
                                                        <div className="mb-3">
                                                            <Label>Amount</Label>
                                                            <AvField name="qty" placeholder="Amount" />
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : null}
                                        </Row>
                                        <Row>
                                            {(selected.to && selected.type) || selected.to == 2 ? (
                                                <Col md="2">
                                                    <Button type="submit" color="primary">Submit</Button>
                                                    <Button className="mx-2" type="reset" color="danger">Reset</Button>
                                                </Col>
                                            ) : null}
                                        </Row>

                                    </AvForm>

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        responsive
                                        bordered
                                        data={data}
                                        disableRetreatAfterSorting={true}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>

        </>
    )
}

export default Billings
