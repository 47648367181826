import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr, { options } from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import jwt_decode from "jwt-decode";
import {
  // getUsers,
  // addUser,
  // deleteUser,
  apiError,
  // getPrivilagesOptions,
  // getCompaniesOptions,
  // getBranchesOptions,
  // updateUser,
  getCompaniesMasterOptions,
  getUsersProfile,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLocalbody, getCompany, getDate } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import "./user.scss";
import { CSVLink } from "react-csv";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { get } from "../../helpers/api_helper";

const Users = (props) => {
  const privilage = JSON.parse(localStorage.getItem("authUser")).privilage;
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [userObject, setUserObject] = useState({});
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [usersForTable, setUsersForTable] = useState([]);
  const [userId, setUserId] = useState(1);
  const [currentLocalbody] = useState(getLocalbody());
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [showLocalbody, setShowLocalbody] = useState(true);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedWardMulti, setSelectedWardMulti] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showWard, setShowWard] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [showLocalbodyMulti, setShowLocalbodyMulti] = useState(false);
  const [selectedSalaryType, setSelectedSalaryType] = useState(null);
  const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [showWardMulti, setShowWardMulti] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState();
  // const [localbodyMulti, setLocalbodyMulti] = useState();
  const [selectedBranchMulti, setSelectedBranchMulti] = useState(null);

  const [showGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const [showCommunity, setShowCommunity] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [showQualification, setShowQualification] = useState(false);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [showSkills, setShowSkills] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [showHouseDetails, setShowHouseDetails] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [showSalaryType, setShowSalaryType] = useState(false);

  const [groupoptions, setGroupoptions] = useState([]);
  const [selectedgroup, setSelectedgroup] = useState(null);
  const [showgroup, setShowgroup] = useState(false);
  // const [selectedDistrict, setSelectedDistrict] = useState(null);
  // const [districtOptions, setDistrictOptions] = useState([]);
  // const [showDistrict, setShowDistrict] = useState(true);
  const [selectedWardMultiLocal, setSelectedWardMultiLocal] = useState(null);
  const [wardMultiOptionsLocal, setWardMultiOptionsLocal] = useState([]);
  const { addingUser, deleteUserResponse, error } = useSelector((state) => state.users);

  // const companiesOptions = useSelector((state) => state.companies.companiesOptions);
  // const branchesOptions = useSelector((state) => state.branches.branchesOptions);
  const { companiesMasterOptions } = useSelector((state) => state.companies);
  const [currentCompany] = useState(getCompany());
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [userPrivilege, setUserPrivilege] = useState("");
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [selectedLocalbody, setSelectedLocalbody] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [errors, setErrors] = useState({
    privilegeError: "",
    wardError: "",
    groupError: "",
    companyError: "",
    localbodyError: "",
  });
  const [searchData, setSearchData] = useState({});
  const [searchLocalbodyOptions, setSearchLocalbodyOptions] = useState([]);

  const dispatch = useDispatch();
  let history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    handleTableData();
    //dispatch(getUsers());
    // dispatch(getPrivilagesOptions());
    dispatch(getCompaniesMasterOptions());
    fetch_all_privilege();
    fetch_all_salaryType();
    fetchMasterLocalbodies(currentLocalbody, currentCompany);
    //dispatch(getCompaniesOptions());
  }, [currentLocalbody]);

  useEffect(() => {
    //   let data = [];
    //   // var data =
    //   companiesMasterOptions.data &&
    //     companiesMasterOptions.data.map((el) => {
    //       // if (el?._id === currentCompany) {
    //       let val = {};
    //       val.label = el.company_name;
    //       val.value = el._id;
    //       data.push(val);
    //       // }
    //       // return {
    //       //   label: el.company_name,
    //       //   value: el._id,
    //       // };
    //     });

    //   setCompanyOptions([
    //     {
    //       options: data,
    //     },
    //   ]);

    var user_company =
      companiesMasterOptions.user_company &&
      companiesMasterOptions.user_company.map((item) => {
        return {
          label: item.company_name,
          value: item._id,
        };
      });
    setCompanyOptions([
      {
        options: user_company,
      },
    ]);
  }, [companiesMasterOptions]);

  // const getCompaniesOption = (id) => {
  //   get(`company/list/single_options?privilage=${id}`).then((res) => {
  //     res.data.map((item) => {
  //       item.value = item._id;
  //       item.label = item.company_name;
  //       return item;
  //     });
  //     setCompanyOptions(res.data);
  //   });
  // };

  function fetchMasterLocalbodies(user_id, id) {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    user_id = user_obj._id;
    if (id != null) {
      var url = `${API_URL}company/list/localbodies?id=` + user_id + `&cid=` + id;
    } else {
      var url = `${API_URL}company/list/localbodies?id=` + user_id;
    }
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var localbodies =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.localbody_name,
              value: item._id,
            };
          });

        setSearchLocalbodyOptions([
          {
            options: localbodies,
          },
        ]);
      });
  }
  function handleTableData() {
    let userid = "";
    let privilege = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
      privilege = user_obj?.privilage;
      setUserId(user_obj._id);
      setUserPrivilege(user_obj?.privilage);
    }

    let localId = searchData?.searchlocalbody_id ? searchData.searchlocalbody_id : "";
    let block_status = searchData?.search_status ? searchData.search_status : 0;
    var url = `${API_URL}user?user_id=` + userid + "&localbody_id=" + currentLocalbody + "&local_id=" + localId + "&status=" + block_status;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let dataToBeExported = [];
        result.map((item, index) => {
          item.id = index + 1;
          let exportItem = {};
          //item.name1 = `${item.firstName}`;
          if (item?.lastName) {
            item.name1 = item?.firstName + " " + item?.lastName;
          } else {
            item.name1 = item?.firstName;
          }
          item.privilage1 = item.privilage && item.privilage.privilege_name;
          // item.company &&
          //   item.company.forEach((element) => {
          //     item.company1 = element.company_name;
          //   });
          item.companyE = null;
          item.company1 = (
            <div>
              <ul>
                {item.company.map((element) => {
                  item.companyE = item.companyE ? item.companyE + "," + element.company_name : element.company_name;
                  return <li>{element.company_name}</li>;
                })}
              </ul>
            </div>
          );
          item.localbody = (
            <div>
              <ul>
                {item.local_body &&
                  item.local_body.map((element, index) => {
                    return <li>{element.localbody_name}</li>;
                  })}
              </ul>
            </div>
          );

          item.localbodyName = "";
          item.local_body &&
            item.local_body.map((el) => {
              if (item.localbodyName === "") item.localbodyName = el?.localbody_name;
              else item.localbodyName = item.localbodyName + "," + el?.localbody_name;
            });
          item.branch1 = item.branch && item.branch.name;

          if (item.app_user === 1) {
            item.appUser = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-android"
                  style={{
                    fontSize: "1.5em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                    color: "green",
                  }}
                // onClick={() => {
                //   handleClickOpenForm(item);
                // }}
                ></i>
              </div>
            );
          }
          item.date = moment(item.date).format("DD-MM-YYYY");
          // item.localbody_name =  getLocalbody();
          // item.type = item?.cust_type;
          // item.district_name = item?.district;
          // item.ward_name = item?.ward;

          // item.image = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //       src={`${API_URL}public/uploads/user_images/${item?.user_image}`}
          //       height="160"
          //       width="120"
          //     />
          //   </div>
          // );

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {privilege && privilege == "61fa7275a7cd7dbee0b92b7b" ? (
                <i
                  className="fas fa-key"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    setShowModal(true);
                    setUserIdToBeUpdated(item._id);
                  }}></i>
              ) : null}

              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/user/" + item._id);
                  //window.location.assign("/user/" + item._id);
                }}></i>

              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  preUpdateUser(item);
                  toTop();
                }}></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setUserIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}></i>
            </div>
          );
          if (item?.status === 0) {
            item.block_status = (
              <>
                <LockOpenIcon
                  style={{
                    color: "green",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => updateStatus(item)}
                />
              </>
            );
          }
          if (selectedStatus?.value === 1) {
            item.block_status = (
              <>
                <LockOpenIcon
                  style={{
                    color: "yellow",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => resignStatus(item)}
                />
              </>
            );
          } else if (item?.status === 2) {
            item.block_status = (
              <>
                <LockOpenIcon
                  style={{
                    color: "red",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => updateStatus(item)}
                />
              </>
            );
          }
          //export data
          exportItem.id = item.id;
          exportItem.Date = item.date;
          exportItem.Name = item.name1;
          exportItem.Mobile = item.mobile;
          exportItem.Address = item?.address;
          exportItem.DOB = item?.user_dob ? moment(item.user_dob).format("MM-DD-YYYY") : "";
          exportItem.Privilege = item.privilage1;
          exportItem.Company = item?.companyE;
          exportItem.Localbody = item.localbodyName;
          exportItem["Join Date"] = item?.user_joining_date;
          exportItem.Info = item.app_user === 1 ? "App User" : "CRM User";
          dataToBeExported.push(exportItem);
        });

        setUsersForTable(result);
        setDataToBeExported(dataToBeExported);
      });
  }

  const updateStatus = (item) => {
    axios
      .put(`${API_URL}security/userblock?user_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.status === 0) {
            toastr.success("User unblocked successfully");
          } else toastr.success("User blocked successfully");

          handleTableData();
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const resignStatus = (item) => {
    axios
      .put(`${API_URL}}user/resign_status?_id=` + item._id, item, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.data.status === 0) {
            toastr.success("User unblocked successfully");
          } else toastr.success("User blocked successfully");

          handleTableData();
        } else {
          toastr.error("Failed");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
              multi_localbody: el?.multi_localbody,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }
  function fetch_all_salaryType() {
    axios
      .get(`${API_URL}user/salarytype`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var salarytype_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.type,
              value: el._id,
            };
          });

        setSalaryTypeOptions([
          {
            options: salarytype_data,
          },
        ]);
      });
  }
  function fetch_all_companylocalbodies(id) {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
      //setUserId(user_obj._id);
    }
    axios
      .get(`${API_URL}user/companylocalbody?company_id=` + id + "&user_id=" + userid, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // let localbody_data = [];

        let localbody_data =
          res.data.data &&
          res.data.data.map((el) => {
            // if (el?._id === currentLocalbody) {
            //   let val = {};
            //   val.label = el.localbody_name;
            //   val.value = el._id;
            //   localbody_data.push(val);
            // }
            return {
              label: el.localbody_name,
              value: el._id,
            };
          });

        setBranchOptions([
          {
            options: localbody_data,
          },
        ]);
      });
  }

  // function fetch_all_district(localbody) {
  //   axios
  //     .get(`${API_URL}api/staff/districts?localbody_id=` + localbody, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data && res.data.success === true) {
  //         var district_data =
  //           res.data.data &&
  //           res.data.data.map((el) => {
  //             return {
  //               label: el.district_name,
  //               value: el._id,
  //             };
  //           });
  //       } else {
  //         district_data = [];
  //       }

  //       setDistrictOptions([
  //         {
  //           options: district_data,
  //         },
  //       ]);
  //     });
  // }
  useEffect(() => {
    setUserObject({ ["addedby"]: 1 });
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);

      setUserId(obj._id);
      setUserObject({
        ...userObject,
        ["addedby"]: userId,
      });
    }
  }, [userId]);

  // useEffect(() => {
  //   if (selectedCompany !== null) {
  //     dispatch(getBranchesOptions(selectedCompany.value));
  //   }
  // }, [selectedCompany]);

  // useEffect(() => {
  //   if (addUserResponse.type === "success") {
  //     toastr.success(addUserResponse.message);
  //     handleTableData();
  //     formRef.current.reset();
  //     setSelectedBranch(null);
  //     setSelectedBranchMulti(null);
  //     setSelectedGroup(null);
  //     setSelectedPrivilege(null);
  //     setSelectedWardMulti(null);
  //     setSelectedCompany(null);
  //     setSelectedSalaryType(null);
  //     setSelectedDistrict(null);
  //     setSelectedWard(null);
  //     setUserObject({
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       username: "",
  //       mobile: "",
  //       salary: "",
  //       password: "",
  //       address: "",
  //     });
  //   } else if (addUserResponse.type === "failure") {
  //     toastr.error(error.data.message, addUserResponse.message);
  //   }
  // }, [addUserResponse]);

  useEffect(() => {
    if (deleteUserResponse.type === "success") {
      toastr.success(deleteUserResponse.message);
      setUserIdToBeDeleted(null);
      handleTableData();
    } else if (deleteUserResponse.type === "failure") {
      toastr.error(error.data.message, deleteUserResponse.message);
    }
  }, [deleteUserResponse]);

  // useEffect(() => {
  //   if (updateUserResponse.type === "success") {
  //     setShowModal(false);
  //     setUserIdToBeUpdated(null);
  //     setPasswordObject({});
  //     handleTableData();
  //     setSelectedBranch(null);
  //     setSelectedBranchMulti(null);
  //     setSelectedGroup(null);
  //     setSelectedPrivilege(null);
  //     setSelectedWardMulti(null);
  //     setSelectedCompany(null);
  //     setSelectedSalaryType(null);
  //     setSelectedDistrict(null);
  //     setSelectedWard(null);
  //     setUserObject({
  //       firstName: "",
  //       lastName: "",
  //       email: "",
  //       username: "",
  //       mobile: "",
  //       salary: "",
  //       password: "",
  //       address: "",
  //     });
  //     formRef.current.reset();
  //     toastr.success(updateUserResponse.message);
  //   } else if (updateUserResponse.type === "failure") {
  //     toastr.error(error.data.message, updateUserResponse.message);
  //   }
  // }, [updateUserResponse]);

  let preUpdateUser = (item) => {
    axios
      .get(`${API_URL}user/single?user_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            let privilegeData = item.privilage || {};
            let privilege = {
              label: privilegeData?.privilege_name,
              value: privilegeData?._id,
              multi_localbody: privilegeData?.multi_localbody,
            };
            item.privilage_value = privilege?.value;
            handleSelectedPrivilage(privilege);

            let companyValues = [];
            let companyArr = [];
            item.company &&
              item.company.forEach((element) => {
                companyValues.push({
                  label: element?.company_name,
                  value: element?._id,
                });
                companyArr.push(element?._id);
              });
            item.company_value = companyArr;
            item.company = companyArr;
            // fetch_all_companylocalbodies(companyValues.value);
            // setSelectedCompany(companyValues);
            handleSelectChange(companyValues, "company");
            //handleSelectedCompany(companyValues);
            let localbodyID = "";
            if (privilegeData.privilege_name === "Operator Admin" || privilegeData.privilege_name === "Reporter Admin" || privilegeData?.multi_localbody) {
              let localBody = [];
              let localbodyMulti = [];
              item.local_body &&
                item.local_body.forEach((element) => {
                  let arrVal = {
                    label: element.localbody_name,
                    value: element._id,
                  };
                  localBody.push(arrVal);
                  localbodyMulti.push(arrVal.value);
                });
              item.local_body = localbodyMulti;
              handleSelectChange(localBody, "localbodymulti", true);
            } else {
              let localBody = [];
              let arrLocalBody = {};
              item.local_body &&
                item.local_body.forEach((element) => {
                  arrLocalBody = {
                    label: element.localbody_name,
                    value: element._id,
                  };
                  localBody.push(arrLocalBody.value);
                });
              item.local_body = localBody;
              localbodyID = arrLocalBody.value;
              handleSelectedBranch(arrLocalBody);
            }

            // let district = {};
            // item.district &&
            //   item.district.forEach((element) => {
            //     district = {
            //       label: element.district_name,
            //       value: element._id,
            //     };
            //   });
            // item.district = district?.value;
            // handleSelectChange(district, "district");

            let salaryType = item.salary_type;
            let salarytype = {
              label: salaryType?.type,
              value: salaryType?._id,
            };
            item.salary_type = salarytype?.value;
            handleSelectChange(salarytype, "salaryType");

            let aditional_skill = item.additional_skill;
            let adnskill = {
              label: aditional_skill,
              value: aditional_skill,
            };
            item.additional_skill = adnskill?.value;
            handleSelectChange(adnskill, "aditional_skill");

            let comunity = item.community;
            let comty = {
              label: comunity,
              value: comunity,
            };
            item.community = comty?.value;
            handleSelectChange(comty, "comunity");

            let qualifictn = item.qualification;
            let quali = {
              label: qualifictn,
              value: qualifictn,
            };
            item.qualification = quali?.value;
            handleSelectChange(quali, "qualifictn");

            let house_details = item.hks_house_details;
            let house = {
              label: house_details,
              value: house_details,
            };
            item.hks_house_details = house?.value;
            handleSelectChange(house, "house_details");

            let gendr = item.gender;
            let gndr = {};

            if (gendr === 0) {
              gndr = {
                label: "Male",
                value: 0,
              };
            } else if (gendr === 1) {
              gndr = {
                label: "Female",
                value: 1,
              };
            } else if (gendr === 2) {
              gndr = {
                label: "Transgender",
                value: 2,
              };
            }

            setSelectedGender(gndr);

            item.gender = gndr.value;
            handleSelectChange(gndr, "gendr");

            if (privilegeData.privilege_name === "Health Inspector") {
              let wardData = [];
              let wardDataMulti = [];
              item.ward &&
                item.ward.forEach((element) => {
                  let arrWard = {
                    label: element.ward_name,
                    value: element._id,
                  };
                  wardData.push(arrWard);
                  wardDataMulti.push(arrWard.value);
                });
              item.ward = wardDataMulti;
              handleSelectChange(wardData, "wardMulti");
            } else if (
              privilegeData?.privilege_name === "Helper" ||
              privilegeData?.privilege_name === "Team Leader" ||
              privilegeData?.privilege_name === "Driver" ||
              privilegeData?.privilege_name === "Supervisor" ||
              privilegeData?.privilege_name === "Super Leader"
            ) {
              let wardData = [];
              let wardDataMulti = [];
              item.ward &&
                item.ward.forEach((element) => {
                  let arrWard = {
                    label: element.ward_name,
                    value: element._id,
                  };
                  wardData.push(arrWard);
                  wardDataMulti.push(arrWard.value);
                });
              item.ward = wardDataMulti;
              handleSelectChange(wardData, "wardMultiLocal");
            } else {
              let ward = {};
              let wardArr = [];
              item.ward &&
                item.ward.forEach((element) => {
                  ward = {
                    label: element.ward_name,
                    value: element._id,
                  };
                  wardArr.push(ward.value);
                });
              item.ward = wardArr;
              handleSelectChange(ward, "ward");
              fetch_all_group(localbodyID, ward);
            }

            let group = [];
            let groupDataMulti = [];
            item.user_group &&
              item.user_group.forEach((element) => {
                let arrGrp = {
                  label: element.group_name,
                  value: element._id,
                };
                group.push(arrGrp);
                groupDataMulti.push(arrGrp.value);
              });
            item.user_group = groupDataMulti;
            if (
              privilegeData?.privilege_name === "Helper" ||
              privilegeData?.privilege_name === "Team Leader" ||
              privilegeData?.privilege_name === "Driver" ||
              privilegeData?.privilege_name === "Supervisor" ||
              privilegeData?.privilege_name === "Super Leader"
            ) {
              handleSelectChange(group, "localgroup");
            } else handleSelectChange(group, "group");
            item.lastName = item?.lastName ? item.lastName : "";
            item.address = item?.address ? item.address : "";
            item.user_dob = item?.user_dob ? item.user_dob : "";
            item.user_joining_date = item?.user_joining_date ? item.user_joining_date : "";
            item.user_resigning_date = item?.user_resigning_date ? item.user_resigning_date : "";
            item.account_no = item?.account_no ? item.account_no : "";
            item.age = item?.age ? item.age : "";
            item.id_number = item?.id_number ? item.id_number : "";
            item.pf_esi_number = item?.pf_esi_number ? item.pf_esi_number : "";
            item.salary = item?.salary ? item.salary : "";
            item.health_insurance_number = item?.health_insurance_number ? item.health_insurance_number : "";
            setUserIdToBeUpdated(item._id);
            setUserObject({ ...item, password: null });
          });
      });
  };
  let preUpdateUserPassword = (item) => {
    setUserIdToBeUpdated(item._id);
    setShowModal(true);
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  // useEffect(() => {
  //   let userData = [];

  //   users.map((item, index) => {
  //     item.action = (
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         {/* <i
  //           className="uil-key-skeleton"
  //           style={{ fontSize: "1em", cursor: "pointer" }}
  //           onClick={() => {
  //             preUpdateUserPassword(item);
  //           }}
  //         ></i> */}
  //         <i
  //           className="fas fa-eye"
  //           style={{
  //             fontSize: "1em",
  //             cursor: "pointer",
  //             marginLeft: "0.2em",
  //             marginRight: "0.5em",
  //           }}
  //           // onClick={() => {
  //           //   handleClickOpenForm(item);
  //           // }}
  //         ></i>
  //         <i
  //           className="uil-edit-alt"
  //           style={{
  //             fontSize: "1em",
  //             cursor: "pointer",
  //             marginLeft: "0.5em",
  //             marginRight: "0.5em",
  //           }}
  //           onClick={() => {
  //             toTop();
  //             preUpdateUser(item);
  //           }}
  //         ></i>
  //         <i
  //           className="uil-trash-alt"
  //           style={{ fontSize: "1em", cursor: "pointer" }}
  //           onClick={() => {
  //             setUserIdToBeDeleted(item._id);
  //             setConfirmDeleteAlert(true);
  //           }}
  //         ></i>
  //       </div>
  //     );
  //     item.id = index + 1;
  //     item.name1 = `${item.firstName} ${item.lastName}`;

  //     item.privilage1 = item.privilage && item.privilage.name;
  //     item.company1 = item.company && item.company.name;
  //     item.branch1 = item.branch && item.branch.name;

  //     // if (item.user_image != "") {
  //     item.user_image = (
  //       <div style={{ textAlign: "center" }}>
  //         <img
  //           src={`${API_URL}public/uploads/user_images/${item?.user_image}`}
  //           height="50"
  //           width="50"
  //         />
  //       </div>
  //     );
  //     // }
  //     userData.push(item);
  //   });
  //   setUsersForTable(userData);
  // }, [users]);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Email",
      //   field: "email",
      //   sort: "asc",
      //   width: 200,
      // },
      // {
      //   label: "Username",
      //   field: "username",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },
      {
        label: "Privilege",
        field: "privilage1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Company",
        field: "company1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Localbody",
        field: "localbody",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "block_status",
        sort: "asc",
        width: 50,
      },
      {
        label: "Info",
        field: "appUser",
        sort: "asc",
        width: 50,
      },
      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: usersForTable,
  };

  function handleChangeUser(e) {
    let name = e.target.name;
    let value = e.target.value;
    setUserObject({ ...userObject, [name]: value });
  }
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var ward_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.ward_name,
                value: el._id,
              };
            });
        } else {
          ward_data = [];
        }

        // var ward_data =
        //   res.data.data &&
        //   res.data.data.map((el) => {
        //     return {
        //       label: el.ward_name,
        //       value: el._id,
        //     };
        //   });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  function handleSelectedPrivilage(value) {
    // getCompaniesOption(value.value);
    let userobject = userObject;
    if (value.label === "Admin" || value.label === "Super Admin") {
      setSelectedBranch(null);
      userobject.local_body = [];
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["local_body"]: [],
      //   ["ward"]: [],
      //   ["user_group"]: [],
      // });
    }
    if (
      // value.label === "Admin" ||
      // value.label === "Super Admin" ||
      value.label === "Operator Admin" ||
      value.label === "Reporter Admin"
    ) {
      setSelectedBranch(null);
      // setSelectedDistrict(null);
      // setShowDistrict(false);
      userobject.local_body = [];
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["local_body"]: [],
      //   ["ward"]: [],
      //   ["user_group"]: [],
      //   // ["district"]: "",
      // });
      setShowLocalbody(false);
    } else {
      setShowLocalbody(true);
      // setShowDistrict(true);
    }

    if (
      value.label === "Helper" ||
      value.label === "Team Leader" ||
      value.label === "Driver" ||
      value.label === "Supervisor" ||
      value.label === "Super Leader"
    ) {
      setShowGroup(true);
      setShowWard(true);
      setSelectedGroup(null);
      setSelectedBranch(null);
      setSelectedCompany(null);
      setSelectedBranchMulti(null);
      setSelectedWard(null);
      userobject.local_body = [];
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["local_body"]: [],
      //   ["ward"]: [],
      //   ["user_group"]: [],
      // });
    } else {
      setShowGroup(false);
    }

    if (value.label === "Operator Admin" || value.label === "Reporter Admin" || value?.multi_localbody) {
      setShowLocalbodyMulti(true);
      if (value?.multi_localbody) {
        setShowLocalbody(false);
      }
    } else {
      setSelectedBranchMulti(null);
      // setUserObject({ ...userObject, ["local_body"]: [] });
      setShowLocalbodyMulti(false);
    }

    if (
      value.label === "Team Leader" ||
      value.label === "Operator Admin" ||
      value.label === "Helper" ||
      value.label === "Supervisor" ||
      value.label === "Admin" ||
      value.label === "Super Admin" ||
      value.label === "Area Admin" ||
      value.label === "Driver" ||
      value.label === "Super Leader"
    ) {
      setShowGender(true);
      setShowCommunity(true);
      setShowQualification(true);
      setShowSkills(true);
      setShowHouseDetails(true);
      setShowSalaryType(true);
      setSelectedGender(null);
      setSelectedCommunity(null);
      setSelectedQualification(null);
      setSelectedSkills(null);
      setSelectedHouse(null);
      setSelectedSalaryType(null);
      userObject.user_group = [];
      userObject.local_body = [];
    } else {
      setShowGender(false);
      setShowCommunity(false);
      setShowQualification(false);
      setShowSkills(false);
      setShowHouseDetails(false);
      setShowSalaryType(false);
    }

    if (
      value.label === "Helper" ||
      value.label === "Team Leader" ||
      value.label === "Driver" ||
      value.label === "Ward Member" ||
      value.label === "Supervisor" ||
      value.label === "Super Leader"
    ) {
      setShowWard(true);
      setSelectedWard(null);
      setSelectedBranch(null);
      userobject.local_body = [];
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["local_body"]: [],
      //   ["ward"]: [],
      //   ["user_group"]: [],
      // });
    } else setShowWard(false);

    if (value.label === "Health Inspector") {
      setSelectedBranch(null);
      setShowWardMulti(true);
      setShowWard(false);
      userobject.local_body = [];
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["local_body"]: [],
      //   ["ward"]: [],
      //   ["user_group"]: [],
      // });
      setSelectedWard(null);
    } else {
      setSelectedWardMulti(null);
      setShowWardMulti(false);
    }
    if (value.label === "Chairman" || value.label === "Secretary") {
      setSelectedWard(null);
      setSelectedGroup(null);
      userobject.ward = [];
      userobject.user_group = [];
      // setUserObject({
      //   ...userObject,
      //   ["ward"]: [],
      //   ["user_group"]: [],
      // });
    }
    let errorVal = errors;
    if (
      value.label === "Helper" ||
      value.label === "Team Leader" ||
      value.label === "Driver" ||
      value.label === "Supervisor" ||
      value.label === "Super Leader"
    ) {
      setShowWard(false);
      setShowGroup(false);
      setSelectedWard(null);
      setSelectedGroup(null);
      setShowgroup(true);
      userobject.ward = [];
      userobject.user_group = [];

      if (selectedGroup === null) {
        errorVal.groupError = "Please select group";
      }
      if (selectedWardMultiLocal === null) {
        errorVal.wardError = "Please select ward";
      }
      // setErrors({
      //   ...errorVal,
      // });
    } else {
      setShowgroup(false);
      errorVal.groupError = "";
      errorVal.wardError = "";
      setSelectedgroup(null);
      setSelectedWardMultiLocal(null);
      setWardMultiOptionsLocal([]);
    }
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    // let errorVal = errors;
    // if (
    //   value.label !== "Helper" ||
    //   value.label !== "Team Leader" ||
    //   value.label !== "Driver" ||
    //   value.label !== "Supervisor"
    // ) {
    //   // errorVal.wardError = "";
    //   errorVal.groupError = "";
    // } else {
    //   // if (selectedWard === null) {
    //   //   errorVal.wardError = "Please select ward";
    //   // }
    //   if (selectedgroup === null) {
    //     errorVal.groupError = "Please select group";
    //   }
    //   setErrors({
    //     ...errorVal,
    //   });
    // }
    errorVal.privilegeError = "";
    setSelectedPrivilege(value);
    setErrors({
      ...errorVal,
    });

    if (userIdTobeUpdated) {
      setUserObject({
        ...userObject,
        userobject,
        ["privilage_value"]: value.value,
      });
    } else setUserObject({ ...userObject, userobject, ["privilage"]: value.value });
    //setUserObject({ ...userObject, ["privilage"]: value.value });
  }

  // function handleSelectedCompany(value) {
  //   // let newValue = {
  //   //   name: value.label,
  //   //   _id: value.value,
  //   // };
  //   setSelectedCompany(value);
  //   let val = [];
  //   val.push(value.value);
  //   fetch_all_companylocalbodies(value.value);
  //   setUserObject({ ...userObject, ["company"]: val });
  // }
  function handleSelectedBranch(value) {
    // let newValue = {
    //   name: value.label,
    //   _id: value.value,
    // };
    let val = [];

    val.push(value.value);

    setSelectedBranch(value);
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedgroup(null);
    // setSelectedDistrict(null);
    setGroupOptions([]);
    setSelectedWardMultiLocal(null);
    setWardMultiOptionsLocal([]);
    fetch_all_localgroup(value.value);
    fetch_all_wards(value.value);
    //fetch_all_district(value.value);
    setErrors({
      ...errors,
      ["localbodyError"]: "",
    });
    setUserObject({ ...userObject, ["local_body"]: val });
  }

  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const handleValidSubmit = (event, values) => {
    if (selectedPrivilege === null || selectedCompany === null || selectedBranch === null) {
      let errorValue = errors;
      if (selectedPrivilege === null) {
        errorValue.privilegeError = "Please select privilege";
      }
      if (selectedCompany === null) {
        errorValue.companyError = "Please select company";
      }
      if (selectedBranch === null) {
        errorValue.localbodyError = "Please select localbody";
      }
      setErrors({
        ...errorValue,
      });
      return;
    }
    if (
      selectedPrivilege.label === "Helper" ||
      selectedPrivilege.label === "Team Leader" ||
      selectedPrivilege.label === "Driver" ||
      selectedPrivilege.label === "Supervisor" ||
      selectedPrivilege.label === "Super Leader"
    ) {
      let errorVal = errors;
      if (selectedgroup === null || selectedWardMultiLocal === null) {
        if (selectedgroup === null) {
          errorVal.groupError = "Please select group";
        }
        if (selectedWardMultiLocal === null) {
          errorVal.wardError = "Please select ward";
        }
        setErrors({
          ...errorVal,
        });
        return;
      }
    }
    if (userIdTobeUpdated) {
      axios
        .put(`${API_URL}user?user_id=` + userIdTobeUpdated, userObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            let result = res.data.data;
            toastr.success("User updated successfully");
            setShowModal(false);
            setUserIdToBeUpdated(null);
            setPasswordObject({});
            handleTableData();
            setSelectedBranch(null);
            setSelectedBranchMulti(null);
            setSelectedGroup(null);
            setSelectedPrivilege(null);
            setSelectedWardMulti(null);
            setSelectedCompany(null);
            setSelectedSalaryType(null);
            setSelectedGender(null);
            setSelectedCommunity(null);
            setSelectedQualification(null);
            setSelectedSkills(null);
            setSelectedHouse(null);
            // setSelectedDistrict(null);
            setSelectedgroup(null);

            setShowGender(false);
            setShowCommunity(false);
            setShowQualification(false);
            setShowSkills(false);
            setShowHouseDetails(false);
            setShowSalaryType(false);

            setShowgroup(false);
            setSelectedWard(null);
            setUserObject({
              firstName: "",
              lastName: "",
              email: "",
              username: "",
              mobile: "",
              salary: "",
              password: "",
              address: "",
              user_dob: "",
              user_joining_date: "",
              user_resigning_date: "",
              account_no: "",
              age: "",
              health_insurance_number: "",
              id_number: "",
            });

            formRef.current.reset();
            setErrors({
              privilegeError: "",
              wardError: "",
              groupError: "",
              companyError: "",
              localbodyError: "",
            });
            if (userId === result._id) {
              let data = {
                user_id: userId,
              };
              dispatch(getUsersProfile(data));
            }
            setSelectedWardMultiLocal(null);
            setWardMultiOptionsLocal([]);
          } else {
            toastr.error("Failed to update user");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}user`, userObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            let result = res.data.data;
            toastr.success("User created successfully");
            handleTableData();
            formRef.current.reset();
            setSelectedBranch(null);
            setSelectedBranchMulti(null);
            setSelectedGroup(null);
            setSelectedPrivilege(null);
            setSelectedWardMulti(null);
            setSelectedCompany(null);
            setSelectedSalaryType(null);
            setSelectedGender(null);
            setSelectedCommunity(null);
            setSelectedQualification(null);
            setSelectedSkills(null);
            setSelectedHouse(null);
            // setSelectedDistrict(null);
            setSelectedgroup(null);

            setShowGender(false);
            setShowCommunity(false);
            setShowQualification(false);
            setShowSkills(false);
            setShowHouseDetails(false);
            setShowSalaryType(false);

            setShowgroup(false);
            setSelectedWard(null);
            setUserObject({
              firstName: "",
              lastName: "",
              email: "",
              username: "",
              mobile: "",
              salary: "",
              password: "",
              address: "",
              user_dob: "",
              user_joining_date: "",
              user_resigning_date: "",
              account_no: "",
              pf_esi_number: "",
              id_number: "",
              age: "",
              health_insurance_number: "",
            });
            setErrors({
              privilegeError: "",
              wardError: "",
              groupError: "",
              companyError: "",
              localbodyError: "",
            });
            if (userId === result._id) {
              let data = {
                user_id: userId,
              };
              dispatch(getUsersProfile(data));
            }
            setSelectedWardMultiLocal(null);
            setWardMultiOptionsLocal([]);
          } else {
            toastr.error("Failed to create user");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // userIdTobeUpdated
    //   ? dispatch(updateUser(userObject))
    //   : dispatch(addUser(userObject));
  };

  const fetch_all_group = (localbody, selected) => {
    axios
      .get(`${API_URL}api/staff/groups?localbody_id=` + localbody + "&ward_id=" + selected.value, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const fetch_all_localgroup = (localbody) => {
    axios
      .get(`${API_URL}user/local_group?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
                ward: el.group_ward,
              };
            });
        } else {
          group_data = [];
        }

        setGroupoptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            closeModal();
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };

  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setUserObject({
            ...userObject,
            [`user_image`]: response.data.file.filename,
          });
          // toastr.success("Image Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const uploadDocument = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}user/image`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setUserObject({
            ...userObject,
            [`user_documents`]: response.data.file.filename,
          });
          // toastr.success("Document Uploaded");
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteUserImage = () => {
    setUserObject({
      ...userObject,
      [`user_image`]: "",
    });
  };
  const deleteUserDocument = () => {
    setUserObject({
      ...userObject,
      [`user_documents`]: "",
    });
  };
  const handleSelectChange = (selected, name, update) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        let localbody = selectedBranch?.value ? selectedBranch.value : "";
        fetch_all_group(localbody, selected);
        let val = [];
        val.push(selected.value);
        setUserObject({ ...userObject, ["ward"]: [...val] });
        break;
      case "group":
        setSelectedGroup(selected);
        setErrors({
          ...errors,
          ["groupError"]: "",
        });
        let groupArray = [];
        selected &&
          selected.forEach((element) => {
            groupArray.push(element.value);
          });
        setUserObject({ ...userObject, ["user_group"]: [...groupArray] });
        break;
      case "localbodymulti":
        let arrayName = [];
        selected &&
          selected.forEach((element) => {
            arrayName.push(element.value);
          });
        setSelectedBranchMulti(selected);
        setSelectedBranch({});
        if (showgroup || update) {
          fetch_all_localgroup(selected.map((val) => val.value));
          // fetch_all_wards(value.value);
        }
        setUserObject({ ...userObject, ["local_body"]: [...arrayName] });
        break;
      case "wardMulti":
        setSelectedWardMulti(selected);
        let wardArray = [];
        selected.forEach((element) => {
          wardArray.push(element.value);
        });
        setUserObject({ ...userObject, ["ward"]: [...wardArray] });
        break;
      case "salaryType":
        setSelectedSalaryType(selected);
        setUserObject({
          ...userObject,
          salary_type: selected.value,
        });
        break;
      // case "district":
      //   setSelectedDistrict(selected);
      //   setUserObject({
      //     ...userObject,
      //     ["district"]: selected.value,
      //   });
      //   break;
      case "company":
        let comVal = [];
        if (selected) {
          setSelectedCompany(selected);
          comVal = selected.map((data) => data.value);
          fetch_all_companylocalbodies(comVal.join(","));
        } else {
          setSelectedCompany(null);
          setSelectedBranch(null);
          setSelectedgroup(null);
          setGroupoptions([]);
          // setSelectedDistrict(null);
          // setDistrictOptions([]);
        }

        setErrors({ ...errors, companyError: "" });

        if (userIdTobeUpdated) {
          setUserObject({ ...userObject, company_value: comVal });
        } else setUserObject({ ...userObject, company: comVal });
        break;
      case "localgroup":
        setSelectedgroup(selected);
        setErrors({ ...errors, groupError: "" });
        let localgroupArray = [];
        // let array = [];
        selected &&
          selected.forEach((element) => {
            localgroupArray.push(element.value);
            // element.ward &&
            //   element.ward.forEach((el) => {
            //     array.push(el);
            //   });
          });
        let data = {
          user_group: localgroupArray,
        };
        axios
          .post(`${API_URL}user/group_wise_ward`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            let ward_data =
              res.data.data &&
              res.data.data.map((ward) => {
                return {
                  label: ward?.ward_name,
                  value: ward?._id,
                  group: ward?.group,
                };
              });
            setWardMultiOptionsLocal([{ options: ward_data }]);
          });

        // if (selected !== null && selectedWardMultiLocal !== null) {
        //   selectedWardMultiLocal &&
        //     selectedWardMultiLocal.map((value) => {
        //       value.group &&
        //         value.group.map((grp) => {
        //           selected &&
        //             selected.map((item) => {
        //               if (item?.value === grp) {
        //               }
        //             });
        //         });
        //     });
        // }
        // if (selected === null && selectedWardMultiLocal !== null) {
        //   setSelectedWardMultiLocal(null);
        // }
        setUserObject({
          ...userObject,
          ["user_group"]: [...localgroupArray],
          // ["ward"]: [...array],
        });
        break;
      case "wardMultiLocal":
        let array = [];
        setSelectedWardMultiLocal(selected);
        selected &&
          selected.map((item) => {
            array.push(item?.value);
          });
        setUserObject({
          ...userObject,
          ["ward"]: [...array],
        });
        if (selected === null) {
          setErrors({
            ...errors,
            ["wardError"]: "Please select ward",
          });
        } else {
          setErrors({
            ...errors,
            ["wardError"]: "",
          });
        }
        break;

      case "gendr":
        setSelectedGender(selected);
        setUserObject({
          ...userObject,
          ["gender"]: selected.value,
        });
        break;

      case "comunity":
        setSelectedCommunity(selected);
        setUserObject({
          ...userObject,
          ["community"]: selected.value,
        });
        break;

      case "qualifictn":
        setSelectedQualification(selected);
        setUserObject({
          ...userObject,
          ["qualification"]: selected.value,
        });
        break;

      case "aditional_skill":
        setSelectedSkills(selected);
        setUserObject({
          ...userObject,
          ["additional_skill"]: selected.value,
        });
        break;

      case "house_details":
        setSelectedHouse(selected);
        setUserObject({
          ...userObject,
          ["hks_house_details"]: selected.value,
        });
        break;

      default:
        break;
    }
  };
  const handleDate = (e) => {
    setUserObject({
      ...userObject,
      [e.target.name]: e.target.value,
    });
  };
  const reset = () => {
    setSearchData({
      searchlocalbody_id: "",
      search_status: "",
    });
    setSelectedLocalbody(null);
    setSelectedStatus(null);
    handleTableData();
  };
  const handleSearchSelectChange = (selected, name) => {
    switch (name) {
      case "searchlocalbody_id":
        setSelectedLocalbody(selected);
        setSearchData({
          ...searchData,
          searchlocalbody_id: selected.value,
        });
        break;

      case "search_status":
        setSelectedStatus(selected);
        setSearchData({
          ...searchData,
          search_status: selected.value,
        });
        break;

      default:
        break;
    }
  };
  const handleSearch = () => {
    handleTableData();
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}user` + "/" + userIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("User deleted successfully");
                  handleTableData();
                } else {
                  toastr.error("Failed to delete user");
                  return;
                }
              });
            // dispatch(deleteUser(userIdToBeDeleted));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}>
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md">
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}>
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button className="btn btn-dark" style={{ marginRight: "1rem" }} onClick={closeModal}>
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Users" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">First name</Label>
                          <AvField
                            name="firstName"
                            placeholder="First name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={userObject.firstName}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Last name</Label>
                          <AvField
                            name="lastName"
                            placeholder="Last name"
                            type="text"
                            //errorMessage="Enter Last name"
                            className="form-control"
                            //validate={{ required: { value: true } }}
                            id="validationCustom02"
                            value={userObject.lastName}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.email}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Username</Label>
                          <AvField
                            name="username"
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter valid Username"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.username}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Mobile</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            errorMessage="Please provide a valid mobile."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={userObject.mobile}
                            onChange={handleChangeUser}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">DOB</Label>
                          <input className="form-control" type="date" id="user_dob" name="user_dob" value={userObject?.user_dob || ""} onChange={handleDate} />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Date Of Joining</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="user_joining_date"
                            name="user_joining_date"
                            value={userObject?.user_joining_date || ""}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Date Of Resigning</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="user_resigning_date"
                            name="user_resigning_date"
                            value={userObject?.user_resigning_date || ""}
                            onChange={handleDate}
                            min={userObject?.user_joining_date}
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="privilage"
                            value={selectedPrivilege}
                            onChange={(value) => {
                              handleSelectedPrivilage(value);
                            }}
                            options={privilegeOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedPrivilege === null ? errors.privilegeError : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Company</Label>
                          <Select
                            name="company"
                            value={selectedCompany}
                            // isDisabled={userIdTobeUpdated ? (privilage === "61fa7275a7cd7dbee0b92b7b" ? false : true) : false}
                            isMulti={true}
                            onChange={(selected) => {
                              handleSelectChange(selected, "company");
                            }}
                            options={companyOptions}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedCompany === null ? errors.companyError : ""}
                          </p>
                        </div>
                      </Col>
                      {showLocalbody === true ? (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Localbody</Label>
                            <Select
                              name="branch"
                              value={selectedBranch}
                              onChange={(value) => {
                                handleSelectedBranch(value);
                              }}
                              options={branchOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedBranch === null ? errors.localbodyError : ""}
                            </p>
                          </div>
                        </Col>
                      ) : null}
                      {showLocalbodyMulti === true ? (
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>Localbody</Label>
                            <Select
                              isMulti
                              name="branch"
                              value={selectedBranchMulti}
                              onChange={(selected) => {
                                handleSelectChange(selected, "localbodymulti");
                              }}
                              options={branchOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {/* {showDistrict === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>District</Label>
                            <Select
                              name="district"
                              value={selectedDistrict}
                              onChange={(selected) => {
                                handleSelectChange(selected, "district");
                              }}
                              options={districtOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null} */}
                      {showWard === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Ward</Label>
                            <Select
                              name="ward"
                              value={selectedWard}
                              onChange={(selected) => {
                                handleSelectChange(selected, "ward");
                              }}
                              options={wardOptions}
                              classNamePrefix="select2-selection"
                            />
                            {/* <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedWard === null ? errors.wardError : ""}
                            </p> */}
                          </div>
                        </Col>
                      ) : null}
                      {showWardMulti === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Ward</Label>
                            <Select
                              isMulti
                              name="ward"
                              value={selectedWardMulti}
                              onChange={(selected) => {
                                handleSelectChange(selected, "wardMulti");
                              }}
                              options={wardOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {showGroup === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Group</Label>
                            <Select
                              isMulti
                              name="group_id"
                              value={selectedGroup}
                              onChange={(selected) => {
                                handleSelectChange(selected, "group");
                              }}
                              options={groupOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : null}
                      {showgroup === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Group</Label>
                            <Select
                              isMulti
                              name="group_id"
                              value={selectedgroup}
                              onChange={(selected) => {
                                handleSelectChange(selected, "localgroup");
                              }}
                              options={groupoptions}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedgroup === null ? errors.groupError : ""}
                            </p>
                          </div>
                        </Col>
                      ) : null}
                      {showgroup === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Ward</Label>
                            <Select
                              isMulti
                              name="ward"
                              value={selectedWardMultiLocal}
                              onChange={(selected) => {
                                handleSelectChange(selected, "wardMultiLocal");
                              }}
                              options={wardMultiOptionsLocal}
                              classNamePrefix="select2-selection"
                            />
                            <p className="text-danger" style={{ fontSize: "11px" }}>
                              {selectedWardMultiLocal === null ? errors.wardError : ""}
                            </p>
                          </div>
                        </Col>
                      ) : null}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>PF/ESI Details</Label>
                            <AvField
                              name="pf_esi_number"
                              type="text"
                              className="form-control"
                              value={userObject?.pf_esi_number}
                              placeholder="PF/ESI details"
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Aadhar/Id number</Label>
                            <AvField
                              name="id_number"
                              type="text"
                              className="form-control"
                              value={userObject?.id_number}
                              placeholder="Adhar/Id number"
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {showGender === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Gender</Label>
                            <Select
                              name="gender"
                              value={selectedGender}
                              classNamePrefix="select2-selection"
                              options={[
                                { label: "Male", value: 0 },
                                { label: "Female", value: 1 },
                                { label: "Transgender", value: 2 },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "gendr");
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Age</Label>
                            <AvField
                              name="age"
                              type="number"
                              className="form-control"
                              value={userObject?.age}
                              placeholder="Age"
                              min={0}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {showCommunity === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Community</Label>
                            <Select
                              name="community"
                              value={selectedCommunity}
                              classNamePrefix="select2-selection"
                              options={[
                                { label: "SC", value: "SC" },
                                { label: "ST", value: "ST" },
                                { label: "OBC", value: "OBC" },
                                { label: "General", value: "General" },
                                { label: "Minority(Muslim, Christian)", value: "Minority(Muslim, Christian)" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "comunity");
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {showQualification === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Qualification</Label>
                            <Select
                              name="qualification"
                              value={selectedQualification}
                              classNamePrefix="select2-selection"
                              options={[
                                { label: "Primary education(1-7)", value: "Primary education(1-7)" },
                                { label: "High School", value: "High School" },
                                { label: "Higher Secondary", value: "Higher Secondary" },
                                { label: "Graduation & above", value: "Graduation & above" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "qualifictn");
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Health card/Insurance coverage details</Label>
                            <AvField
                              name="health_insurance_number"
                              type="text"
                              className="form-control"
                              value={userObject?.health_insurance_number}
                              placeholder="Health card/Insurance coverage"
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {showSkills === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Additional Skills</Label>
                            <Select
                              name="additional_skill"
                              classNamePrefix="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "aditional_skill");
                              }}
                              value={selectedSkills}
                              options={[
                                { label: "Driving license", value: "Driving license" },
                                { label: "Tailoring", value: "Tailoring" },
                                { label: "Fashion", value: "Fashion" },
                              ]}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {showHouseDetails === true ? (
                        <Col md="3">
                          <div className="md-3">
                            <Label>HKS House Details</Label>
                            <Select
                              name="hks_house_details"
                              value={selectedHouse}
                              classNamePrefix="select2-selection"
                              options={[
                                { label: "Own house", value: "Own house" },
                                { label: "HKS rent house", value: "HKS rent house" },
                                { label: "HKS living in joint ownership house", value: "HKS living in joint ownership house" },
                              ]}
                              onChange={(selected) => {
                                handleSelectChange(selected, "house_details");
                              }}
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Salary</Label>
                            <AvField
                              name="salary"
                              // type="text"
                              type="number"
                              className="form-control"
                              id="validationCustom03"
                              value={userObject.salary}
                              placeholder="Salary"
                              onChange={handleChangeUser}
                              // validate={{number:true}}
                              min={0}
                            />
                          </div>
                        </Col>
                      ) : (
                        " "
                      )}

                      {showSalaryType === true ? (
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Salary Type</Label>
                            <Select
                              name="salary_type"
                              value={selectedSalaryType}
                              onChange={(selected) => {
                                handleSelectChange(selected, "salaryType");
                              }}
                              options={salaryTypeOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedPrivilege?.label === "Team Leader" ||
                        selectedPrivilege?.label === "Operator Admin" ||
                        selectedPrivilege?.label === "MANAGER" ||
                        selectedPrivilege?.label === "Helper" ||
                        selectedPrivilege?.label === "Supervisor" ||
                        selectedPrivilege?.label === "Admin" ||
                        selectedPrivilege?.label === "Super Admin" ||
                        selectedPrivilege?.label === "Area Admin" ||
                        selectedPrivilege?.label === "Driver" ||
                        selectedPrivilege?.label === "Super Leader" ? (
                          <>
                           <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">Account Number</Label>
                            <AvField
                              name="account_no"
                              placeholder="Account Number"
                              type="text"
                              errorMessage="Enter Account Number"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={userObject.account_no}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">IFSC CODE</Label>
                            <AvField
                              name="IFSC"
                              placeholder="IFSC CODE"
                              type="text"
                              errorMessage="Enter IFSC CODE"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={userObject.IFSC||""}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                          </>
                       
                        
                      ) : (
                        ""
                      )}

                      {/* <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Image</Label>
                          <AvField
                            name="user_image"
                            id="user_image"
                            type="file"
                            errorMessage="Upload  Image"
                            className="form-control"
                            onChange={uploadImage}
                          />
                        </div>
                      </Col> */}
                      {userIdTobeUpdated ? null : (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Password</Label>
                            <AvField
                              name="password"
                              placeholder="Password"
                              type="password"
                              errorMessage=" Please provide a valid password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={userObject.password}
                              onChange={handleChangeUser}
                            />
                          </div>
                        </Col>
                      )}
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Blood Group</Label>
                          <AvField
                            name="blood_group"
                            placeholder="Blood Group"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            value={userObject?.blood_group}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            className="form-control"
                            id="validationCustom03"
                            value={userObject.address}
                            onChange={handleChangeUser}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {userObject?.user_image ? (
                            <div div className="img-wraps">
                              {userObject.user_image && userObject.user_image.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${userObject.user_image}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${userObject.user_image}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteUserImage}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="user_image" type="file" className="form-control" id="validationCustom03" onChange={uploadImage} rows="1" />
                          )}
                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Document</Label>
                          {userObject?.user_documents ? (
                            <div div className="img-wraps">
                              {userObject.user_documents && userObject.user_documents.startsWith("http") ? (
                                <img alt="" width="150" height="150" src={`${userObject.user_documents}`} />
                              ) : (
                                <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${userObject.user_documents}`} />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={deleteUserDocument}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField name="user_documents" type="file" className="form-control" id="validationCustom03" onChange={uploadDocument} rows="1" />
                          )}
                          {/* {uploadProgress && uploadProgress < 100 && (
                            <div className="mt-4">
                              <Progress color="primary" value={uploadProgress}>
                                Uploading {uploadProgress}%
                              </Progress>
                            </div>
                          )} */}
                        </div>
                      </Col>
                      <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                        {userIdTobeUpdated ? (
                          <Button color="primary" type="submit" disabled={addingUser ? true : false}>
                            {addingUser ? "Updating" : "Update"}
                          </Button>
                        ) : (
                          <Button color="primary" type="submit" disabled={addingUser ? true : false}>
                            {addingUser ? "Adding" : "Submit"}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {userPrivilege && userPrivilege == "61fa7275a7cd7dbee0b92b7b" ? null : (
                    <CSVLink data={dataToBeExported} filename={"Users_" + getDate(new Date()) + ".xls"}>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                            Export
                          </Tooltip>
                        }>
                        <i
                          className="fas fa-file-export"
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            color: "green",
                            position: "relative",
                            float: "right",
                            top: "8px",
                            // zIndex:"999"
                          }}></i>
                      </OverlayTrigger>
                    </CSVLink>
                  )}
                  {userPrivilege && userPrivilege == "61fa7275a7cd7dbee0b92b7b" ? (
                    <Row className="mb-3">
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Localbody</Label>
                          <Select
                            name="searchlocalbody_id"
                            value={selectedLocalbody}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "searchlocalbody_id");
                            }}
                            options={searchLocalbodyOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="search_status"
                            value={selectedStatus}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "UnBlocked", value: 0 },
                              { label: "Resigned", value: 1 },
                              { label: "Blocked", value: 2 },
                            ]}
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "search_status");
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div style={{ display: "flex" }}>
                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="primary" type="submit" onClick={() => handleTableData()}>
                              Search
                            </Button>
                          </div>

                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="success" type="submit">
                              <CSVLink data={dataToBeExported} filename={"Users_" + getDate(new Date()) + ".xls"} style={{ color: "white" }}>
                                Export
                              </CSVLink>
                            </Button>
                          </div>

                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button color="danger" type="reset" onClick={() => reset()}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  <MDBDataTable
                    id="userTableId"
                    responsive
                    bordered
                    data={data}
                  // searching={true}
                  // info={true}
                  // disableRetreatAfterSorting={true}
                  // entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(connect(mapStateToProps, { apiError })(Users));

Users.propTypes = {
  error: PropTypes.any,
  users: PropTypes.array,
};
