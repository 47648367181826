import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table as table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import "./warehouse.scss";
import { apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Select from "react-select";
import { getLocalbody } from "../../../helpers/globalFunctions";
import moment from "moment";

const AddLocation = (props) => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [masterObject, setmasterObject] = useState({});
  const [warehouseIdTobeUpdated, setWarehouseIdToBeUpdated] = useState(null);
  const [warehouseIdToBeDeleted, setwarehouseIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [wardOptions, setWardOptions] = useState([]);
  const [errors, setErrors] = useState({
    wardError: "", typeError: ""
  });
const [warehouseType, setwarehouseType] = useState([])
  const [details, setDeails] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  const [uploadProgress, setUploadProgress] = useState();


  useEffect(() => {
    handleTableData();
    fetch_ward();
    fetchAlltype()
  }, []);


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const fetch_ward = () => {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let ward_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.ward_name,
              value: item._id,
            };
          });
        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  };

  function handleTableData() {
    axios
      .get(`${API_URL}warehouse/list?localbody_id=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item?.warehouse_date).format("DD-MM-YYYY");
            item.ward = item?.ward?.ward_name;
            item.type=item?.category?.name
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    preUpdateWarehouse(item);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    setwarehouseIdToBeDeleted(item._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
          });
        setDeails(result);
      });
  }

  let preUpdateWarehouse = (item) => {
    setWarehouseIdToBeUpdated(item._id);

    axios
      .get(`${API_URL}warehouse/singlelist` + "/" + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        console.log(result,"resultytttttt");
        setSelectedType({
          label: result?.warehouseCategory?.name, 
          value: result?.warehouseCategory?._id
        })
        setSelectedWard({
          label: result?.warehouse_ward?.ward_name,
          value: result?.warehouse_ward?._id
        })
        result.longitude = result?.location?.longitude;
        result.latitude = result?.location?.latitude;
        result.warehouseCategory=result?.warehouseCategory?._id;
        setmasterObject(result);
      });
  };

  function fetchAlltype() {
    axios.get(`${API_URL}api/staff/warehouse-category/option`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      console.log(res, "res");
      setwarehouseType(res.data.data)
    })
  }

  
console.log(masterObject);

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Warehouse",
        field: "warehouse_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
        width: 400,
      },
      {
        label: "Length",
        field: "length",
        width: 200,
      },
      {
        label: "Width",
        field: "width",
        width: 200,
      },
      {
        label: "Height",
        field: "height",
        width: 200,
      },
      {
        label: "Volume",
        field: "volume",
        width: 200,
      },
      {
        label: "Type",
        field: "type",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  const handleValidSubmit = () => {

    let data1 = {
      warehouse_localbody: currentLocalbody,
      warehouse_ward: masterObject?.warehouse_ward?._id,
      length: masterObject?.length,
      width: masterObject?.width,
      height: masterObject?.height,
      location: {
        longitude: masterObject?.longitude,
        latitude: masterObject?.latitude,
      },
      warehouse_name: masterObject?.warehouse_name,
      image: masterObject?.image,
      warehouseCategory: masterObject?.warehouseCategory,
      _id: masterObject?._id
    }

    let data = {
      warehouse_localbody: currentLocalbody,
      warehouse_ward: masterObject?.warehouse_ward,
      length: masterObject?.length,
      width: masterObject?.width,
      height: masterObject?.height,
      location: {
        longitude: masterObject?.longitude,
        latitude: masterObject?.latitude,
      },
      warehouse_name: masterObject?.warehouse_name,
      image: masterObject?.image,
      warehouseCategory: masterObject?.warehouseCategory,
      _id: masterObject?._id
    }


    if (selectedWard === null || selectedType === null) {
      let errorVal = errors;
      if (selectedWard === null) {
        errorVal.wardError = "Please select ward"
      }
      if (selectedType === null) {
        errorVal.typeError = "Please select type"
      }
      setErrors({
        ...errorVal
      })
    } else {
      if (warehouseIdTobeUpdated) {
        axios
          .put(
            `${API_URL}warehouse`, data1, {
            headers: {
              "x-access-token": accessToken,
            },
          }
          )
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Warehouse updated successfully");
              formRef.current.reset();
              setWarehouseIdToBeUpdated(null);
              handleTableData();
              setmasterObject({});
              setErrors({
                wardError: "", typeError: ""
              });
              setSelectedWard(null);
              setSelectedType(null);
            } else {
              toastr.error("Failed to update warehouse");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}warehouse`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Warehouse added successfully");
              handleTableData();
              formRef.current.reset();
              setmasterObject({});
              setErrors({
                wardError: "", typeError: ""
              });
              setSelectedWard(null);
              setSelectedType(null);
            } else {
              toastr.error("Failed to add Warehouse");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  function handleInputChange(e) {
    setmasterObject({ ...masterObject, [e.target.name]: e.target.value });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "warehouse_ward":
        setSelectedWard(selected);
        setmasterObject({
          ...masterObject,
          ["warehouse_ward"]: selected.value,
        });
        setErrors({
          wardError: ""
        });
        break;

      case "warehouseCategory":
        setSelectedType(selected);
        setmasterObject({
          ...masterObject,
          ["warehouseCategory"]: selected.value,
        });
        setErrors({
          typeError: ""
        });
        break;

      default:
        break;
    }
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("warehouse_image", e.target.files[0])
    axios
      .post(`${API_URL}warehouse/uploadImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total))
        },
      }).then(res => {
        if (res.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`image`]: res.data.file.filename,
          });
          toastr.success(res.data.message)
        } else {
          toastr.error("Image uploaded failed");
          return;
        }
      })
  }

  const deleteImage = () => {
    setmasterObject({
      ...masterObject, [`image`]: ""
    })
  }


  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}warehouse` + "/" + warehouseIdToBeDeleted, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Warehouse deleted successfully");
                    if (
                      masterObject &&
                      masterObject?._id === warehouseIdToBeDeleted
                    ) {
                      formRef.current.reset();
                      setWarehouseIdToBeUpdated(null);
                      handleTableData();
                      setmasterObject({});
                      setErrors({
                        wardError: "", typeError: ""
                      });
                      setSelectedWard(null);
                      setSelectedType(null)
                    }
                    handleTableData();
                  } else {
                    toastr.error("Failed to delete Warehouse");
                    return;
                  }
                })
                .catch((err) => {
                  toastr.error(err.response.data.message);
                  return;
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}

        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Warehouse" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >

                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Ward</Label>
                            <Select
                              name="warehouse_ward"
                              id="exampleSelect"
                              style={{ appearance: "auto" }}
                              value={selectedWard}
                              onChange={(selected) =>
                                handleSelectChange(selected, "warehouse_ward")
                              }
                              options={wardOptions}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedWard === null
                                ? errors.wardError
                                : ""}
                            </p>
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Ware House Name</Label>
                            <AvField
                              name="warehouse_name"
                              placeholder="Location"
                              type="text"
                              value={masterObject?.warehouse_name}
                              errorMessage="Enter Location "
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <Label>Warehouse Category</Label>
                          <Select
                            name="type"
                            value={selectedType}
                            // options={[
                            //   { label: "Main Warehouse", value: 1 },
                            //   { label: "Mini Warehouse", value: 2 }
                            // ]}
                            options={warehouseType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "warehouseCategory")
                            }}
                            classNamePrefix="select2-selection"
                          />
                          <p className="text-danger" style={{ fontSize: "11px" }}>
                            {selectedType === null
                              ? errors.typeError
                              : ""}
                          </p>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Latitude</Label>
                            <AvField
                              name="latitude"
                              placeholder="Latitude"
                              type="number"
                              value={masterObject?.latitude}
                              errorMessage="Enter Latitude "
                              className="form-control"
                              validate={{
                                required: { value: true },
                                pattern: {
                                  value: "^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}",
                                  errorMessage:
                                    "Provide valid latitude",
                                },
                              }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">
                              Longitude
                            </Label>
                            <AvField
                              name="longitude"
                              placeholder="Longitude"
                              type="number"
                              value={masterObject?.longitude}
                              errorMessage="Enter Longitude"
                              className="form-control"
                              validate={{
                                required: { value: true },
                                // pattern: {
                                //   value: "/^-?([1]?[1-7][1-9]|[1]?[1-8][0]|[1-9]?[0-9])\.{1}\d{1,6}$/",
                                //   errorMessage:
                                //     "Provide valid longitude",
                                // }
                              }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Length</Label>
                            <AvField
                              name="length"
                              placeholder="Length"
                              type="number"
                              value={masterObject?.length}
                              errorMessage="Enter Length"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Width</Label>
                            <AvField
                              name="width"
                              placeholder="Width"
                              type="number"
                              value={masterObject?.width}
                              errorMessage="Enter Width"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Height</Label>
                            <AvField
                              name="height"
                              placeholder="Height"
                              type="number"
                              value={masterObject?.height}
                              errorMessage="Enter Height"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              onChange={handleInputChange}
                              min={0}
                            />
                          </div>
                        </Col>

                        <Col md="3">
                          <div className="mb-3">
                            <Label>Upload Image</Label>
                            {masterObject?.image ? (
                              <div className="img-wraps">
                                {masterObject?.image && masterObject?.image?.startsWith('http') ? (
                                  <img alt='' width="150" height="150" src={`${masterObject?.image}`} />
                                ) : (
                                  <img alt="" width="150" height="150" src={`${API_URL}uploads/warehouse_images/${masterObject?.image}`} />
                                )}
                                <button className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteImage}
                                  style={{ width: '150px' }}
                                >Delete</button>
                              </div>
                            ) : (
                              <AvField name="image" onChange={uploadImage} type="file" className="form-control" id="validationCustom03" rows={1} />
                            )}
                          </div>
                        </Col>

                        <Col md="1" style={{ paddingTop: "25px" }}>
                          {warehouseIdTobeUpdated ? (
                            <Button color="primary" type="update">
                              {"Update"}
                            </Button>
                          ) : (
                            <Button color="primary" type="submit">
                              {"Submit"}
                            </Button>
                          )}
                        </Col>
                      </Row>

                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="warehouseId"
                      responsive
                      bordered
                      data={data}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
      </React.Fragment>
    </>
  );
};

const mapStateToProps = (state) => { };
export default withRouter(connect(mapStateToProps, { apiError })(AddLocation));
