import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ReactSelect from "react-select";
import axios from "axios";
import { getLocalbody, getUserId } from "../../helpers/globalFunctions";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { Pagination } from "@mui/material"
import toastr from "toastr";


const Index = () => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [verifyModal, setVerifyModal] = useState(false);
  const [master, setMaster] = useState([]);
  const [droplistTable, setdroplistTable] = useState([])
  const [warehouseType, setwarehouseType] = useState()
  const [warehouse, setwarehouse] = useState()
  const [filterObj, setfilterObj] = useState({})
  const [page, setPage] = useState(1)
  const [selectedOption, setselectedOption] = useState()
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)
  const [dropverifyItems, setdropverifyItems] = useState([])
  const [originalItems, setoriginalItems] = useState([])
  const [disputedItems, setDisputedItems] = useState({});
  const [mainDropId, setmainDropId] = useState(null)

  const [bucket, setBucket] = useState([
    { item: "Thermocol", weight: 12, bag: 2 },
    { item: "Plastics", weight: 6, bag: 1 },
    { item: "Chappals", weight: 12, bag: 2 },
  ]);

  useEffect(() => {
    fetachAlldroplist()
    // fetchaAllverifyItem()
  }, [])

  useEffect(() => {
    fetchAlltype();
  }, [])

  useEffect(() => {
    fetchAllwarehouse()
  }, [filterObj])

  function fetchAlltype() {
    axios.get(`${API_URL}api/staff/warehouse-category/option`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      setwarehouseType(res.data.data)
    })
  }

  function fetchAllwarehouse() {
    axios.get(`${API_URL}api/staff/warehouse/option?localbodyId=${getLocalbody()}&categoryId=${filterObj?.type}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      setwarehouse(res.data.data)
    })
  }

  const handleValueChange = (key, value, index) => {
    const updatedItems = dropverifyItems.map((item, i) =>
      i === index ? { ...item, [key]: value } : item
    );
    setdropverifyItems(updatedItems);
    setDisputedItems((prev) => {
      prev[index] = updatedItems[index]
      return prev
    });
  };
  
  function handleCloseModal() {
    setdropverifyItems([])
    setoriginalItems([])
    setDisputedItems({})
    setmainDropId(null)
    setVerifyModal(false)
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 50,
      },
      {
        label: "Date",
        field: "date",
        width: 100,
      },
      {
        label: "Time",
        field: "time",
        width: 100,
      },

      {
        label: "Unique Id",
        field: "uniqueId",
        width: 100,
      },
      {
        label: "Warehouse",
        field: "warehouse",
        width: 100,
      },
      // {
      //   label: "Item",
      //   field: "item",
      //   width: 100,
      // },
      {
        label: "Weight",
        field: "totalWeight",
        width: 50,
      },
      {
        label: "Bag",
        field: "totalBag",
        width: 50,
      },
      {
        label: "Drop Person",
        field: "staff",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: droplistTable
  };





  function fetachAlldroplist() {
    axios.get(`${API_URL}api/staff/maindrop/list/verify?localbodyId=${getLocalbody()}&start=${page}&limit=${limit}&warehouseId=${filterObj?.warehouse}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data;
      setCount(res.data.count)
      result && result.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
        item.time = item?.time ? moment(item.time, "hh mm ss").format("hh:mm A") : "--"
        item.warehouse = item?.warehouseId?.warehouse_name;
        item.staff = item?.addedBy?.firstName + "" + item?.addedBy?.lastName
        item.action = (
          <>
            <Button
              color="primary"
              onClick={() => handledropverify(item?._id)}
              style={{
                marginRight: "5px",
                fontSize: "smaller",
                padding: "3px 16px",
              }}
            >
              Verify
            </Button>
            <Button
              color="danger"
              size="sm"
              style={{ fontSize: "smaller", padding: "3px 16px" }}
            >
              Reject
            </Button>
          </>
        )
        return item
      })
      setdroplistTable(result)
    })
  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  function handleselected(seleted, name) {
    setselectedOption({ ...selectedOption, [name]: seleted })
    setfilterObj({ ...filterObj, [name]: seleted.value })
  }


  function fetchaAllverifyItem(id) {
    setmainDropId(id)
    axios.get(`${API_URL}api/staff/maindrop/list/verify/items?mainDropId=${id}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      setdropverifyItems(res.data.data)
      setoriginalItems(res.data.data)
      setVerifyModal(true)
    })
  }
  function handledropverify(id) {
    fetchaAllverifyItem(id)
  }


  function handleverify() {
    let data = {
      mainDropId: mainDropId,
      verifiedItem: originalItems,
      disputedItem: Object.values(disputedItems),
      userId: getUserId()
    }
    axios.post(`${API_URL}api/staff/maindrop/verify`, data, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      toastr.success("Verified")
      handleCloseModal()
      fetachAlldroplist()
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Home" breadcrumbItem="Drops" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Type</Label>
                          <ReactSelect
                            name="Customer"
                            classNamePrefix="select2-selection"
                            options={warehouseType}
                            onChange={(selected) => {
                              handleselected(selected, "type")
                            }}
                            value={selectedOption?.type || null}
                          // options={[{label:"Mini WareHouse",value:"1"},{label:"Main WareHouse",value:"2"},{label:"RRF",value:"3"},]}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Warehouse</Label>
                          <ReactSelect
                            name="Customer"
                            classNamePrefix="select2-selection"
                            options={warehouse}
                            onChange={(selected) => {
                              handleselected(selected, "warehouse")
                            }}
                            value={selectedOption?.warehouse || null}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">From Date</Label>
                          <AvField name="fromDate" type="date" />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">To Date</Label>
                          <AvField name="toDate" type="date" />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                  <MDBDataTable
                    id="eventTableId"
                    data={data}
                    sortable={false}
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    bordered
                    responsive
                    entries={20}
                  />
                  <div class="row align-items-center">
                    <div
                      dataTest="datatable-info"
                      className="col-sm-12 col-md-5"
                    >
                      <div
                        className="dataTables_info"
                        role="status"
                        ariaLive="polite"
                      >
                        Showing {(page - 1) * limit + 1} to{" "}
                        {Math.min(page * limit, count)} of {count} entries
                      </div>
                    </div>
                    <div
                      data-test="datatable-pagination"
                      className="col-sm-12 col-md-7"
                    >
                      <div className="dataTables_paginate">
                        <Pagination
                          page={page}
                          count={Math.ceil(count / limit)}
                          shape="rounded"
                          size="small"
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={verifyModal}
          size="lg"
          toggle={() => {
            handleCloseModal();
          }}
          centered={true}
        >
          <div className="modal-header">
            {/* <h5 className="modal-title mt-0">Complaint </h5> */}
            <button
              type="button"
              onClick={() => {
                handleCloseModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <AvForm onValidSubmit={handleverify}>
              <Row style={{ paddingLeft: "15px" }}>
                <div class="col-md-12">
                  <div>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Total Weight</th>
                          <th>Total Bag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dropverifyItems.map((item, index) => (
                          <tr>
                            <td>
                              <input
                                className="form-control"
                                type="text"

                                disabled
                                value={item.wasteItemName}
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={item.totalWeight}
                                onChange={(e) =>
                                  handleValueChange(
                                    "totalWeight",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                className="form-control"
                                type="text"
                                value={item.totalBag}
                                onChange={(e) =>
                                  handleValueChange(
                                    "totalBag",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {Object.keys(disputedItems).length > 0 ? (
                  <>
                    <Label>
                      Disputed Qantity <input checked={Object.keys(disputedItems).length > 0} type="checkbox" />
                    </Label>
                    {Object.values(disputedItems)?.map((item) => {
                      return (
                        <Row>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.wasteItemName}
                                name="quantity"
                                placeholder="Item"
                                disabled={true}

                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.totalWeight}
                                name="quantity"
                                placeholder="Total Weight"
                                disabled={true}
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <AvField
                                value={item.totalBag}
                                name="quantity"
                                placeholder="Bag"
                                disabled={true}
                              />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
                {/* <Col md="4">
                  <div className="mb-3">
                    <Label>Comments</Label>
                    <AvField name="quantity" placeholder="Comments" />
                  </div>
                </Col> */}
                <Col md="3" style={{ paddingTop: "25px" }}>
                  <Button color="primary" className="me-2" type="submit">
                    {master.length > 0 ? "Dispute" : "Verify"}
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Index;
