import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./Warehouseintimation.scss"

const WarehouseIntimationReport = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const data = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      { label: "Date", field: "date", sort: "asc", width: 150 },
      { label: "Driver", field: "name", sort: "asc", width: 270 },
      { label: "Weight", field: "ward", sort: "asc", width: 150 },
      { label: "Bag", field: "volume", sort: "asc", width: 150 },
      { label: "Pending", field: "collected", sort: "asc", width: 150 },
      { label: "Collected", field: "status", sort: "asc", width: 150 },
      { label: "Drop", field: "drop", sort: "asc", width: 150 },
    ],
    rows: [
      {
        id: 1,
        date: "21-09-2024", // Updated format
        name: "Swalih Haneef",
        ward: "25",
        volume: 10,
        collected: 8,
        status: "2",
        drop:"8",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 2,
        date: "21-09-2024", // Updated format
        name: "Akshay ",
        ward: "25",
        volume: 10,
        collected: 8,
        status: "2",
        drop:"8",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: 3,
        date: "21-09-2024", // Updated format
        name: "Swalih Haneef",
        ward: "25",
        volume: 10,
        collected: 8,
        status: "2",
        drop:"8",
        action: (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              className="fas fa-eye"
              onClick={() => setModalOpen(true)}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginRight: "0.5em",
              }}
            ></i>
          </div>
        ),
      },
      
    ],
  };

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Warehouse-IntimationReport" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">From</Label>
                        <AvField name="fromdate" type="date" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">To</Label>
                        <AvField name="Todate" type="date" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Driver</Label>
                        <ReactSelect
                          name="Customer"
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col style={{ paddingTop: "25px" }}>
                      <Button className="me-2" color="danger" type="print">
                        Reset
                      </Button>
                      <Button color="primary" type="print">
                        Export
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
                <MDBDataTable
                  id="ware-house-intimation"
                  responsive
                  bordered
                  data={data}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          show={modalOpen}
          toggle={handleCloseModal}
          size="lg"
          centered={true}
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div class="col-md-12">
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Total Weight</th>
                        <th>Total Bag</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Thermocol</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Plastics</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Chappals</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WarehouseIntimationReport
