import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getDate,
  getFirstday,
  getLocalbody,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import { CSVLink } from "react-csv";
import "./MonthWiseReport.scss";
import { getCustomerType } from "../../../store/actions";
const DatatableTables = () => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    fetch_all_staff(currentLocalbody);
    // dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value))
    }
  }, [selectedCategory]);

  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    category_type = "",
    category_id = ""
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }
    var url =
      `${API_URL}reports/monthwise_report?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id +
      "&category_type=" +
      category_type +
      "&category_id=" +
      category_id;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        
        let dataToBeExported = [];
        let vistHouse = 0;
        let paidHouse = 0;
        let houseAmt = 0;
        let vistShop = 0;
        let paidShop = 0;
        let ShopAmt = 0;
        let totalAmount = 0;
        let totalWeight = 0;

       
   result?.map((item, index) => {

    
            let exportItem = {};
            item.id = index + 1;
            item.month = moment(item._id).format("MMMM YYYY");
            item.total_weight = item.totalweight.toFixed(2);
            //export data
            exportItem.id = item.id;
            exportItem.Month = item.month;
            exportItem.Visited_Houses = item.visited_houses;
            exportItem.Paid_Houses = item.paid_houses;
            exportItem.House_Amt = item.house_amt;
            exportItem.Visited_Shops = item.visited_shops;
            exportItem.Paid_Shops = item.paid_shops;
            exportItem.Shop_Amt = item.shop_amt;
            exportItem.Total = item.total_amount;
            exportItem.Weight = item.total_weight;

            // Calculate the total summary
            totalAmount += item?.total_amount;
            vistHouse += item?.visited_houses;
            paidHouse += item?.paid_houses;
            houseAmt += item?.house_amt;
            vistShop += item?.visited_shops;
            paidShop += item?.paid_shops;
            ShopAmt += item?.shop_amt;
            totalWeight += parseFloat(item?.total_weight);

            dataToBeExported.push(exportItem);
            return item
          });
        // Add a footer row

        if (result.length !== 0) {
          result.push({
            id: "",
            month: <b>Total Summary</b>,
            visited_houses: <b>{vistHouse}</b>,
            paid_houses: <b>{paidHouse}</b>,
            house_amt: <b>{houseAmt}</b>,
            visited_shops: <b>{vistShop}</b>,
            paid_shops: <b>{paidShop}</b>,
            shop_amt: <b>{ShopAmt}</b>,
            total_amount: <b>{totalAmount}</b>,
            total_weight: <b>{totalWeight.toFixed(2)}</b>,
          });
        }
        setDetails(result);
        setDataToBeExported(dataToBeExported);
      });
  }

  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },
      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "House ₹",
        field: "house_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Shop ₹",
        field: "shop_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total ₹",
        field: "total_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Weight(Kg)",
        field: "total_weight",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };
  const handleSelectChange = (selected, name) => {

    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: ""
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   category_id
        // );
        break;
      case "category_type":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setSearchData({
          ...searchData,
          ["category_type"]: selected.value,
          ["category_id"]: "",
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          ["category_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   staff_id,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, group_id, staff_id, category_id);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCategory(null);
    setSelectedCustomerType(null);
    handleTableData();
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    let category_type = searchData?.category_type ? searchData.category_type : "";
    handleTableData(date1, date2, ward_id, group_id, staff_id, category_type, category_id);
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Month Wise Report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Ward</Label>
                          <Select
                            name="ward_id"
                            value={selectedWard}
                            onChange={(selected) => {
                              handleSelectChange(selected, "ward");
                            }}
                            options={wardOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Group</Label>
                          <Select
                            name="group_id"
                            value={selectedGroup}
                            onChange={(selected) => {
                              handleSelectChange(selected, "group");
                            }}
                            options={groupOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select
                            name="staff_id"
                            value={selectedStaff}
                            onChange={(selected) => {
                              handleSelectChange(selected, "staff");
                            }}
                            options={staffOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Category Type</Label>
                          <Select
                            name="category_type"
                            value={selectedCategory}
                            classNamePrefix="select2-selection"
                            options={[
                              { label: "Residential", value: 1 },
                              { label: "Commercial", value: 2 }
                            ]}
                            onChange={(selected) => {
                              handleSelectChange(selected, "category_type")
                            }}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Customer Type</Label>
                          <Select
                            name="category_id"
                            value={selectedCustomerType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "customerType");
                            }}
                            options={customerType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div style={{ display: "flex" }}>
                          <div className="mt-1 mb-3 mx-1"
                            style={{ paddingTop: "22px" }}
                          >
                            <Button
                              color="primary"
                              type="submit"
                              onClick={() => handleSearch()}
                            >
                              Search
                            </Button>
                          </div>
                          {isSuperAdmin() ? (
                          <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "22px" }}
                          >
                            <Button color="success" type="submit">
                              {/* <i className="mdi mdi-microsoft-excel"></i> */}
                              <CSVLink
                                data={dataToBeExported}
                                filename={
                                  "Month_wise_report_" +
                                  getDate(new Date()) +
                                  ".xls"
                                }
                                style={{ color: "white" }}
                              >
                                Export
                              </CSVLink>
                            </Button>
                          </div>
        ) : null}
                          <div className="mt-1 mb-3 mx-1"
                            style={{ paddingTop: "22px" }}
                          >
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>

                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle>View Month Wise Report</CardTitle> */}
                  <hr />
                  <MDBDataTable
                    id="monthWiseReportTableId"
                    responsive
                    bordered
                    data={data}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
