import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";



const WarehouseType = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [masterObj, setmasterObj] = useState({ verificationRequired: false, driverDrop: false, staffDrop: false })
    const [tabledata, settabledata] = useState([])
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [deleteId, setdeleteId] = useState(null)

    const formRef = useRef();

    useEffect(() => {
        fetchallwarehousetype()
    }, [])

    const toTop = () => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };
 
    function handleUpdate(item) {
        toTop()
        setmasterObj({
            _id: item?._id,
            name: item?.name,
            verificationRequired: item?.verificationRequired,
            driverDrop: item?.driverDrop,
            staffDrop: item?.staffDrop
        })
    }



    function handlechange(e) {
        let { name, value } = e.target
        setmasterObj({ ...masterObj, [name]: value })
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setmasterObj((prevState) => ({
            ...prevState,
            [name]: checked, // Set to true when checked, false when unchecked
        }));
    };

    function handleValidSubmit(e, v) {

        if(masterObj?._id){
            axios.post(`${API_URL}warehouse/category/update`, masterObj, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {
                toastr.success("Warehouse Type Updated")
                formRef.current.reset()
                setmasterObj({ verificationRequired: false, driverDrop: false, staffDrop: false })
                fetchallwarehousetype()

    
            }).catch((err) => {
    
            })

        }else{
            axios.post(`${API_URL}warehouse/category`, masterObj, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {
                toastr.success("Warehouse Type Added")
                formRef.current.reset()
                setmasterObj({ verificationRequired: false, driverDrop: false, staffDrop: false })
                fetchallwarehousetype()
    
            }).catch((err) => {
    
            })
        }
      
    }


    function fetchallwarehousetype() {
        axios.get(`${API_URL}warehouse/category/list`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let result = res.data.data
            result && result.map((item, index) => {
                item.id = index + 1;
                item.driverDrop ? item.drive = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-check"></i>
                        </div>
                    </>
                ) : item.drive = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-close">X</i>
                        </div>
                    </>
                )

                item.verificationRequired ? item.vrify = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-check"></i>
                        </div>
                    </>
                ) : item.vrify = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-close">X</i>
                        </div>
                    </>
                )

                item.staffDrop ? item.staff = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-check"></i>
                        </div>
                    </>
                ) : item.staff = (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i class="fa fa-close">X</i>
                        </div>
                    </>
                )

                item.action = (
                    <>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <i
                                className="uil-edit-alt"
                                style={{
                                    fontSize: "1.2em",
                                    cursor: "pointer",
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                }}
                                onClick={() => handleUpdate(item)}
                            ></i>
                            <i
                                className="uil-trash-alt"
                                style={{ fontSize: "1.2em", cursor: "pointer" }}
                                onClick={() => {
                                    setConfirmDeleteAlert(true); 
                                    setdeleteId(item._id); 
                                }}
                                

                            ></i>
                        </div>
                    </>
                )

                return item

            })
            settabledata(result)

        })
    }


    const data = {
        columns: [
            { label: "#", field: "id", sort: "asc", width: 150 },
            // { label: "Date", field: "date", sort: "asc", width: 150 },
            { label: "Name", field: "name", sort: "asc", width: 270 },
            { label: "DriverDrop", field: "drive", sort: "asc", width: 150 },
            { label: "Verification", field: "vrify", sort: "asc", width: 150 },
            { label: "StaffDrop ", field: "staff", sort: "asc", width: 150 },
            { label: "Action", field: "action", sort: "asc", width: 150 },
        ],
        rows: tabledata
    };


    return (
        <>
         {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}warehouse/category/delete/${deleteId}` , {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then((res) => {
                toastr.success("Deleted")
                fetchallwarehousetype()
               
              }).catch((err) => {
                toastr.error(err.response.data.message);
                return;
              })
            setConfirmDeleteAlert(false)
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >Are you sure you want to delete it?</SweetAlert>
      ) : ""}

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="WareHouse Type" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm
                                            ref={formRef}
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmit(e, v)
                                            }}>
                                            <Row>
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Type</Label>
                                                        <AvField
                                                            name="name"
                                                            type="text"
                                                            placeholder="Type"
                                                            validate={{ required: { value: true } }}
                                                            errorMessage="Enter Type"
                                                            className="form-control"
                                                            onChange={handlechange}
                                                            value={masterObj?.name || ""}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="4">
                                                    <div className="mt-4" style={{ paddingTop: "5px" }}>

                                                        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Label style={{ marginRight: "5px" }}>DriverDrop</Label>
                                                                <input type="checkbox" name="driverDrop" checked={masterObj?.driverDrop} onClick={handleCheckboxChange} />
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Label style={{ marginRight: "5px" }}>VerificationRequired</Label>
                                                                <input type="checkbox" name="verificationRequired" checked={masterObj?.verificationRequired} onClick={handleCheckboxChange} />
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                                <Label style={{ marginRight: "5px" }}>StaffDrop</Label>
                                                                <input type="checkbox" name="staffDrop" checked={masterObj?.staffDrop} onClick={handleCheckboxChange} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                        <Button color={masterObj?._id?"warning":"primary"} type="submit" >{masterObj?._id?"Update":"Submit"}</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </AvForm>

                                    </Row>
                                    <Row>
                                        <MDBDataTable
                                            // id="intimationId"
                                            responsive
                                            bordered
                                            data={data}
                                            disableRetreatAfterSorting={true}
                                        />
                                    </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default WarehouseType
