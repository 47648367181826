import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { getLocalbody } from "../../../helpers/globalFunctions";


const WarehouseReport = () => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [modalOpen, setModalOpen] = useState(false);
  const [warehouseType, setwarehouseType] = useState()
  const [warehouse, setwarehouse] = useState()
  const [tabledata, settabledata] = useState([])
  const [selectedOption, setselectedOption] = useState()
  const [filterObj, setfilterObj] = useState({})
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetchAlltype();
  }, [])

  useEffect(() => {
    fetchallWareHouseReport()
    fetchAllwarehouse()
  }, [currentLocalbody, filterObj])

  function fetchAlltype() {
    axios.get(`${API_URL}api/staff/warehouse-category/option`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      console.log(res, "res");
      setwarehouseType(res.data.data)
    })
  }

  function fetchAllwarehouse() {
    axios.get(`${API_URL}api/staff/warehouse/option?localbodyId=${currentLocalbody}&categoryId=${filterObj?.type}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      setwarehouse(res.data.data)
    })
  }


  function fetchallWareHouseReport() {
    axios.get(`${API_URL}api/staff/warehouse/report?localBody=${currentLocalbody}&type=${filterObj?.type}&warehouse_id=${filterObj?.warehouse}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      console.log("res reports", res);
      let result = res?.data?.data
      result && result.map((item, index) => {
        item.id = index + 1
        item.date = item.date ? moment(item.date).format("DD-MM-YYYY") : "--"
        // if (item.volume > item.consumed_volume) {
        //   item.status = (item.consumed_volume/item.volume)*100 +"%"+"filled"
        // } else if (item.volume < item.consumed_volume) {
        //   item.status = (item.consumed_volume/item.volume)*100
        // } else if (item.volume == item.consumed_volume) {
        //   item.status = (item.consumed_volume/item.volume)*100
        // }
        item.status = ((item.consumed_volume / item.volume) * 100).toFixed(2) + "%" + " filled";
        item.items = (
          <>
            <div>

              <ul>
                {item?.wareHouse?.map((el, idx) => (
                  <>
                    <li>{`${el?.wasteitem_name} (${el?.totalBags}/${el?.totalWeight})`}</li>
                  </>
                ))}

              </ul>


            </div>
          </>

        )

        return item
      })
      settabledata(result)
    })
  }

  function handleRset() {
    setfilterObj({})
    setselectedOption({})
  }


  const data = {
    columns: [
      { label: "#", field: "id", sort: "asc", width: 150 },
      // { label: "Date", field: "date", sort: "asc", width: 150 },
      { label: "Warehouse", field: "warehouse_name", sort: "asc", width: 270 },
      { label: "Ward", field: "warehouse_ward", sort: "asc", width: 150 },
      { label: "Volume", field: "volume", sort: "asc", width: 150 },
      { label: "Consumed ", field: "consumed_volume", sort: "asc", width: 150 },
      { label: "Items(bag/weight)", field: "items", sort: "asc", width: 150 },
      { label: "Filled %", field: "status", sort: "asc", width: 150 },
      // { label: "Action", field: "action", sort: "asc", width: 150 },
    ],
    rows: tabledata
  };

  function handleselected(seleted, name) {
    console.log(seleted, name);
    setselectedOption({ ...selectedOption, [name]: seleted })
    setfilterObj({ ...filterObj, [name]: seleted.value })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Warehouse-Report" />
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm>
                  <Row>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">From</Label>
                        <AvField name="fromdate" type="date" />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">To</Label>
                        <AvField name="Todate" type="date" />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <ReactSelect
                          name="Customer"
                          classNamePrefix="select2-selection"
                          options={warehouseType}
                          onChange={(selected) => {
                            handleselected(selected, "type")
                          }}
                          value={selectedOption?.type || null}
                        // options={[{label:"Mini WareHouse",value:"1"},{label:"Main WareHouse",value:"2"},{label:"RRF",value:"3"},]}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Warehouse</Label>
                        <ReactSelect
                          name="Customer"
                          classNamePrefix="select2-selection"
                          options={warehouse}
                          onChange={(selected) => {
                            handleselected(selected, "warehouse")
                          }}
                          value={selectedOption?.warehouse || null}
                        />
                      </div>
                    </Col>
                    <Col style={{ paddingTop: "25px" }}>
                      <Button className="me-2" color="danger" type="print" onClick={handleRset}>
                        Reset
                      </Button>
                      <Button color="primary" type="print">
                        Export
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
                <MDBDataTable
                  // id="intimationId"
                  responsive
                  bordered
                  data={data}
                  disableRetreatAfterSorting={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          show={modalOpen}
          toggle={handleCloseModal}
          size="lg"
          centered={true}
        >
          <div className="modal-header">
            <button
              type="button"
              onClick={() => {
                setModalOpen(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div class="col-md-12">
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Total Weight</th>
                        <th>Total Bag</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Thermocol</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Plastics</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                      <tr>
                        <td>Chappals</td>
                        <td>4 Kg</td>
                        <td> 2</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default WarehouseReport;
