import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Table, Container } from "reactstrap";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import moment from "moment";
import { apiError } from "../../store/actions";
import { jsonToExcel } from "../../helpers/api_helper";


// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import logoDark from "../../assets/images/logo-dark.svg";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLocalbody, getCompany, getDate, getFirstday, formatMoney } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import { CSVLink } from "react-csv";
import "./Incentive.scss";
import { Pagination } from "@mui/material";

const AutoInvoice = (props) => {
  const [staffOptions, setStaffOptions] = useState([]);
  const [details, setDetails] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  // const [currentCompany, serCurrentCompany] = useState(getCompany());
  const [dataToBeExported, setDataToBeExported] = useState([]);

  // const [totalPages, setTotalPages] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [PerPageCount] = useState(20);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);

  // useEffect(() => {
  //   handleTableData();
  // }, [currentLocalbody, currentPage]);

  // console.log(currentPage, PerPageCount, totalPages);

  const handleTableData = async () => {

    setIsLoading(true);
    try {
      const userId = JSON.parse(localStorage.getItem("authUser"))?._id;
      if (!userId) {
        console.warn("authUser not found in localStorage");
        return;
      }

      const startIndex = (page - 1) * limit;

      const params = new URLSearchParams({
        localbodyId: currentLocalbody,
        userId: userId,
        fromDate: searchData.from_date || getFirstday(new Date()),
        toDate: searchData.to_date || getDate(new Date()),
        staffId: searchData.staff_id || "",
        start: startIndex.toString(),
        limit: limit.toString()
      });

      console.log('Request parameters:', {
        startIndex,
        limit,
        page,
        url: `${API_URL}incentive/recordlogs?${params.toString()}`
      });

      const response = await axios.get(`${API_URL}incentive/recordlogs`, {
        params,
        headers: {
          "x-access-token": accessToken,
        },
      });

      console.log('API Response:', response.data);

      if (response.data.data && Array.isArray(response.data.data)) {
        setCount(response.data.count);

        const result = response.data.data.map((item, index) => {
          return(
            {
              ...item,
              id: startIndex + index + 1,
              date: moment(item.date).format("DD-MM-YYYY"),
              time: moment(item.time, "HH:mm:ss").format("hh:mm a"),
              // incentive_date_from: moment(item.fromDate).format("DD-MM-YYYY"),
              fromDate:moment(item.fromDate).format("DD-MM-YYYY"),
              incentive_date_to: moment(item.date).format("DD-MM-YYYY"),
              staff_inc_amount: Number(item.staff_inc_amount).toFixed(2),
              incentive_type: item.incentive_type == 1 ? "Daily" : "Monthly",
              incentive_name: item.incentive_id.incentive_name,
              staff_info: item.staff_id.firstName
            }
          )
        });
        // console.log('result :>> ', result);

        setDetails(result);
      } else {
        console.error('Invalid data format received:', response.data);
        setDetails([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDetails([]);
      dispatch(apiError(error));
    }
   finally {
      setIsLoading(false);
   }
  };

  useEffect(() => {
    handleTableData();
  }, [page, searchData, currentLocalbody]);

  const handleExportData = async () => {
    try {
      setDisableButton(true)
      const userId = JSON.parse(localStorage.getItem("authUser"))?._id;
      if (!userId) {
        console.warn("authUser not found in localStorage");
        return;
      }
  
      const getCurrentUserId = () => {
        try {
          const authUser = localStorage.getItem("authUser");
          if (authUser) {
            const userData = JSON.parse(authUser);
            return userData._id; // This is how you access the ID in your code
          }
          return null;
        } catch (error) {
          console.error("Error getting user ID:", error);
          return null;
        }
      };

    let url = `${API_URL}incentive/recordlogs?localbodyId=${currentLocalbody}&userId=${getCurrentUserId()}&fromDate=${searchData?.from_date}&toDate=${searchData?.to_date}&`;
    if (searchData?.staff_id) {
      url += `staffId=${searchData?.staff_id}&`;
    }
  
     await axios.get(url, {
        headers: {"x-access-token": accessToken},
      }).then((res) => {
        let result = res.data.data;
        let exportData = [];
       
        result.forEach((data) => {
          let exportItem = {
            'Date': data.date,
            'Time': moment(data.time, "HH:mm:ss").format("hh:mm a"),
            'From': moment(data.fromDate).format('DD-MM-YYYY'),
            'To': moment(data.date).format('DD-MM-YYYY'),
            'Incentive Type':data.incentive_type===1?"Daily":"Monthly",
            'Staff':data.staff_id.firstName + ' ' + data.staff_id.lastName,
            'Incentive': data.incentive_id.incentive_name,
            'Amount': data.amount,
            'Visit Count':data.total_visit_count
          };
          exportData.push(exportItem);
        });
        if (exportData.length > 0) {
          jsonToExcel(exportData, `report-${moment().format('DD-MM-YY')}`);
        } else {
          alert('There are no data to export');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        setDisableButton(false);
      });
  }catch(error){
    console.error("Error fetching data:", error);
  };}

  const fetch_all_staff = (id) => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id = user_obj._id;
    axios
      .post(
        `${API_URL}api/staff/filter_staff_list`,
        { localbody_id: currentLocalbody, user_id: user_id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };

  const handleDate = (e) => {
    setSearchData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSelectChange = (selected, name) => {

    if(!selected){
      console.log('Not Selected');
      
    }

    if (name === "staff") {
      setSelectedStaff(selected);
      setSearchData(prev => ({
        ...prev,
        staff_id: selected.value
      }));
    }
  };

  const data = {
    columns: [
      // {
      //   label: "#",
      //   field: "_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "No",
      //   field: "incentive_no",
      //   sort: "asc",
      //   width: 400,
      // },
      {
        label: "From",
        field: "fromDate",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incentive Type",
        field: "incentive_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_info",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incentive",
        field: "incentive_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Visit Count",
        field: "total_visit_count",
        sort: "asc",
        width: 200,
      },

      // {
      //   label: "Group",
      //   field: "group_name",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: details,
  };

  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
      staff_id: "",
    });
    setSelectedStaff(null);
    setPage(1);
  };


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handlePaginate = (event, value) => {
    if (value < 1 || value > Math.ceil(count / limit)) return;
    toTop()
    setPage(value);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            //breadcrumbItem="Monthly Automatic Invoice Generator"
            breadcrumbItem="Incentive"
          />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input className="form-control" type="date" id="from_date" name="from_date" value={searchData?.from_date} onChange={handleDate} />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        value={searchData?.to_date}
                        min={searchData?.from_date}
                        onChange={handleDate}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  {/* <Col md="2">
                    <div className="mb-3">
                      <Label>Ward</Label>
                      <Select
                        name="ward_id"
                        value={selectedWard}
                        onChange={(selected) => {
                          handleSelectChange(selected, "ward");
                        }}
                        options={wardOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Group</Label>
                      <Select
                        name="group_id"
                        value={selectedGroup}
                        onChange={(selected) => {
                          handleSelectChange(selected, "group");
                        }}
                        options={groupOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col> */}
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Staff</Label>
                      <Select
                        name="staff_id"
                        value={selectedStaff}
                        onChange={(selected) => {
                          handleSelectChange(selected, "staff");
                        }}
                        options={staffOptions}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div style={{ display: "flex" }}>
                    {/* <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="primary" onClick={() => setPage(1)}>Search</Button>
                      </div> */}

                      <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                        <Button color="danger" type="submit" onClick={() => reset()}>
                          {"  "}
                          Reset
                        </Button>
                      </div>

                      <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                            <Button 
                              color="success" 
                              onClick={handleExportData}
                            >
                              Export
                            </Button>
                          </div>
                    </div>
                  </Col>

                  {/* <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "30px",cursor:"pointer" }}>
                          <LoopIcon onClick={handleClick}></LoopIcon>
                        </div>

                      </Col> */}
                </Row>

                <Row
                // style={{ display: searchLoader ? "none" : "block" }}
                >
                  <Col md="12">
                    <MDBDataTable
                      id="incentivelistTable"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      paging={false}
                      infoLabel={false}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                    {count > limit && (
                      <div className="pagination-div">
                        <div className="page-details">
                          Showing {(page - 1) * limit + 1} to {Math.min(page * limit, count)} of {count} entries
                        </div>
                        <Pagination
                          color="standard"
                          shape="rounded"
                          defaultPage={1}
                          count={Math.ceil(count / limit)}
                          page={page}
                          onChange={handlePaginate}
                          size="large"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default AutoInvoice;

// AutoInvoice.propTypes = {
//   error: PropTypes.any,
//   invoiceList: PropTypes.array,
// };
