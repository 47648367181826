import React from 'react'
import ReactECharts from 'echarts-for-react';
import { Row } from 'reactstrap';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
const WarehouseDashboard = () => {
    const [alignment, setAlignment] = React.useState('item');
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };


    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: ['Mini-warehouse1', 'Mini-warehouse2', 'Mini-warehouse3', 'Mini-warehouse4', 'Mini-warehouse5', 'Mini-warehouse6', 'Mini-warehouse7'],
        },
        series: [
            {
                name: 'Platic',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#64b946', // Example color
                },
                data: [320, 302, 301, 334, 657, 330, 320],
            },
            {
                name: 'Bottle',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#2b4b20', // Example color
                },
                data: [120, 132, 101, 134, 90, 230, 210],
            },
            {
                name: 'Paper',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#6a7a64', // Example color
                },
                data: [220, 182, 191, 234, 290, 330, 310],
            },
            {
                name: 'test',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#c8d3c4', // Example color
                },
                data: [150, 212, 201, 154, 190, 330, 410],
            },
            {
                name: 'test 2',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#678e67', // Example color
                },
                data: [820, 832, 901, 934, 1290, 1330, 1320],
            },
            {
                name: 'akshay',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#79e079', // Example color
                },
                data: [320, null, null, null, null, 330, 320],
            },
        ],
    };


    // volume chart

    const warehouses = [
        { name: 'Mini-warehouse1', maxVolume: 1000, filledVolume: 320 },
        { name: 'Mini-warehouse2', maxVolume: 1000, filledVolume: 302 },
        { name: 'Mini-warehouse3', maxVolume: 1000, filledVolume: 301 },
        { name: 'Mini-warehouse4', maxVolume: 500, filledVolume: 334 },
        { name: 'Mini-warehouse5', maxVolume: 700, filledVolume: 657 },
        { name: 'Mini-warehouse6', maxVolume: 1000, filledVolume: 330 },
        { name: 'Mini-warehouse7', maxVolume: 800, filledVolume: 320 },
    ];

    // Extract data for the chart
    const warehouseNames = warehouses.map((w) => w.name);
    const filledVolumes = warehouses.map((w) => w.filledVolume);
    const remainingVolumes = warehouses.map((w) => w.maxVolume - w.filledVolume);

    const options1 = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                let tooltipContent = `${params[0].axisValue}<br>`;
                params.forEach((item) => {
                    tooltipContent += `${item.marker} ${item.seriesName}: ${item.value}<br>`;
                });
                return tooltipContent;
            },
        },
        legend: {
            data: ['Filled Volume', 'Remaining Volume'],
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            name: 'Volume',
        },
        yAxis: {
            type: 'category',
            data: warehouseNames,
        },
        series: [
            {
                name: 'Filled Volume',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: '{c}', // Display the value inside the bar
                },
                itemStyle: {
                    color: '#64b946', // Filled volume color
                },
                data: filledVolumes,
            },
            {
                name: 'Remaining Volume',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: '{c}', // Display the value inside the bar
                },
                itemStyle: {
                    color: '#c8d3c4', // Remaining volume color
                },
                data: remainingVolumes,
            },
        ],
    };


    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="item">Item Graph</ToggleButton>
                            <ToggleButton value="volume">Volume Graph</ToggleButton>
                        </ToggleButtonGroup>
                    </Row>
                    {alignment == "item" ? (
                        <>
                            <Row key={alignment}>
                                <ReactECharts option={options} style={{ height: 400, width: '100%' }} />
                            </Row>
                        </>
                    ) : <>
                        <Row key={alignment}>
                            <ReactECharts option={options1} style={{ height: 400, width: '100%' }} />
                        </Row>
                    </>}




                </div>
            </div>

        </>
    )
}

export default WarehouseDashboard