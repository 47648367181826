import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ReactSelect from "react-select";
import axios from "axios";
import { getLocalbody, getUserId } from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { Pagination } from "@mui/material"
import toastr from "toastr";

const Disputed = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [limit, setLimit] = useState(20)
    const [table, settable] = useState([])
    const [show, setshow] = useState(false)
    const [modaldata, setmodaldata] = useState([])

    useEffect(() => {
        fetchAllverifiedDrops()
    }, [])

    const handlePageChange = (e, value) => {
        setPage(value)
    }
    function fetchAllverifiedDrops() {
        axios.get(`${API_URL}api/staff/maindrop/disputed?localbodyId=${getLocalbody()}&start=${page}&limit=${limit}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let result = res.data.data;
            setCount(res.data.count)
            result && result.map((item, index) => {
                item.id = (page - 1) * limit + index + 1
                item.date = item?.date ? moment(item.date).format("DD-MM-YYYY") : "--"
                item.time = item?.time ? moment(item.time, "hh mm ss").format("hh:mm A") : "--"
                item.warehouse = item?.warehouseId?.warehouse_name;
                item.staff = item?.addedBy?.firstName + "" + item?.addedBy?.lastName
                item.action = (
                    <>
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <i
                                className="fas fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    viewVerifiedItems(item?._id)
                                }}
                            ></i>
                            <Button
                                color="primary"
                                style={{
                                    marginRight: "5px",
                                    fontSize: "smaller",
                                    padding: "3px 16px",
                                }}
                                disabled
                            >
                                Verified
                            </Button>

                        </div>

                    </>
                )
                return item
            })
            settable(result)
        })
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 50,
            },
            {
                label: "Date",
                field: "date",
                width: 100,
            },
            {
                label: "Time",
                field: "time",
                width: 100,
            },

            {
                label: "Unique Id",
                field: "uniqueId",
                width: 100,
            },
            {
                label: "Warehouse",
                field: "warehouse",
                width: 100,
            },
            // {
            //   label: "Item",
            //   field: "item",
            //   width: 100,
            // },
            {
                label: "Weight",
                field: "totalWeight",
                width: 50,
            },
            {
                label: "Bag",
                field: "totalBag",
                width: 50,
            },
            {
                label: "Drop Person",
                field: "staff",
                width: 100,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
            },
        ],
        rows: table
    };

    function viewVerifiedItems(id) {
        axios.get(`${API_URL}api/staff/maindrop/verified-items?dropId=${id}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            setshow(true)
            setmodaldata(res.data.data)

        })
    }

    return (
        <>
         <Modal
                isOpen={show}
                size="lg"
                toggle={() => {
                    setshow(false);
                }}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Disputed Item </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setshow(false)
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="modal-body">
                    <AvForm>
                        <Row style={{ paddingLeft: "15px" }}>
                            <div class="col-md-12">
                                <div>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Item</th>
                                                <th>Total Weight</th>
                                                <th>Total Bag</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modaldata?.items?.map((item, index) => (
                                                <tr>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"

                                                            disabled
                                                            value={item.wasteItemName}
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={item.totalWeight}
                                                            disabled

                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            value={item.totalBag}
                                                            disabled

                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Disputed Drops" />
                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <MDBDataTable
                            id="eventTableId"
                            data={data}
                            sortable={false}
                            searching={false}
                            displayEntries={false}
                            paging={false}
                            disableRetreatAfterSorting={true}
                            bordered
                            responsive
                            entries={20}
                        />
                        <div class="row align-items-center">
                            <div
                                dataTest="datatable-info"
                                className="col-sm-12 col-md-5"
                            >
                                <div
                                    className="dataTables_info"
                                    role="status"
                                    ariaLive="polite"
                                >
                                    Showing {(page - 1) * limit + 1} to{" "}
                                    {Math.min(page * limit, count)} of {count} entries
                                </div>
                            </div>
                            <div
                                data-test="datatable-pagination"
                                className="col-sm-12 col-md-7"
                            >
                                <div className="dataTables_paginate">
                                    <Pagination
                                        page={page}
                                        count={Math.ceil(count / limit)}
                                        shape="rounded"
                                        size="small"
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>

                    </Row>

                </div>

            </div>
        </>

    )
}

export default Disputed
