import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { Doughnut } from "react-chartjs-2";
import { HorizontalBar } from "react-chartjs-2";
import ReactECharts from 'echarts-for-react';
const Complaintsdashboard = () => {

    const [chartOptions, setChartOptions] = React.useState({
        series: [{
            name: "Desktops",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: 'Total Complaints by Month',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
            }
        }
    });
   

    const data = {
        labels: ["Active", "Closed"], // Add labels if you want
        datasets: [
            {
                label: "My Dataset",
                data: [20, 40],
                backgroundColor: ["#FF6384", "#36A2EB"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB"]
            }
        ]
    };


    const [chartData] = useState({
        series: [
            {
                data: [400, 200, 50],
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 150,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: "end",
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: true,
            },
            xaxis: {
                categories: [
                    "Public",
                    "Interaction",
                    "Request",
                ],
            },
            colors: [
                "#008FFB", "#00E396", "#FEB019",
            ],
        },
    });



    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Row style={{ rowGap: "20px" }}>
                        <Col md="6">
                            <Card style={{ height: "100%", marginBottom: "0px" }}>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <h6>Complaints</h6>
                                            <h6>Total Complaints: 1500</h6>
                                        </Col>
                                        <Col style={{ display: "flex", justifyContent: "flex-end" }} md="6">
                                            <AvForm>
                                                <AvField
                                                    name="to_date"
                                                    type="date"
                                                    className="form-control"
                                                />
                                            </AvForm>
                                        </Col>
                                    </Row>

                                    <div>
                                        {/* <ReactApexChart options={options} series={series} type="pie" height="220px" className="apex-charts" /> */}
                                        {/* <ReactECharts option={optionhr} style={{ height: '220px', width: '100%' }} /> */}
                                        <ReactApexChart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="bar"
                                            height={210}
                                        />

                                    </div>

                                    {/* <Col md="6" style={{ marginLeft: "400px", display: "flex", cursor: "pointer" }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                          Request
                        </Tooltip>
                      }
                    >
                      <h6 style={{ marginRight: "10px", transition: "color 0.3s", cursor: "pointer" }}>
                        <TaskAltIcon />
                      </h6>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                          Interaction
                        </Tooltip>
                      }
                    >
                      <h6 style={{ marginRight: "10px" }}>
                        <CancelIcon />
                      </h6>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                          Public Complaints
                        </Tooltip>
                      }
                    >
                      <h6>
                        <HourglassBottomOutlinedIcon />
                      </h6>
                    </OverlayTrigger>
                  </Col> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Row>
                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Public Complaints:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>2000</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Request:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>2000</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Interaction:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>2000</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col style={{ marginBottom: "10px" }} md="6">
                                    <Card style={{ marginBottom: "0px", height: "100%" }}>
                                        <CardBody>
                                            <h6>
                                                Escalated:{" "}
                                                <span style={{ fontSize: "13px" }}>
                                                    <b>2000</b>
                                                </span>
                                            </h6>
                                            <Doughnut width={474} height={180} data={data} />
                                        </CardBody>
                                    </Card>
                                </Col>


                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md="6">
                            <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={350} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Complaintsdashboard;
